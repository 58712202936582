// eslint-disable-next-line
export const darkTheme = {
    bg: "bg-dark",
    txt: "text-white",
    bg_color: "black",
    txt_color: "white",
    line_color: "blue",
    Navbar: {
        bg: "secondary",
        variant: "light"
    }
};

// eslint-disable-next-line
export const normalTheme = {
    bg: "",
    txt: "",
    bg_color: "white",
    txt_color: "black",
    line_color: "blue",
    Navbar: {
        bg: "light",
        variant: "dark"
    }
};

export const defaultTheme = darkTheme;
