import React from 'react';
import { Form, FormGroup, Container } from "../shared";

export class ChangeItemForm extends Form {
    _changeItemFormComponentName = null;
    _changeItemFormComponentRef = null;
    _dataLocalStorageKeyName = null;

    constructor(props, ref) {
        super(props);

        this._changeItemFormComponentRef = React.createRef();
        this.onChange = this.onChange.bind(this);
        this.getChangeItemAPIURL = this.getChangeItemAPIURL.bind(this);
        this.change = this.change.bind(this);
        this.onChangeItemApiSuccess = this.onChangeItemApiSuccess.bind(this);
        this.onChangeItemApiFail = this.onChangeItemApiFail.bind(this);
    }

    renderFormComponents() {
        return (
            <FormGroup className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                <Container className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' m-0 p-0 w-100'}>
                {
                    React.createElement(this._changeItemFormComponentName, {
                        ref: this._changeItemFormComponentRef,
                        AppContext: this.props.AppContext,
                        ItemId: this.props.ItemId,
                        Item: this.props.Item
                    })
                }
                </Container>
            </FormGroup>
        );
    }

    onChange(event) {
        if(!this._changeItemFormComponentRef.current.validate()) return -1;

        this.change();

        return 0;
    }

    getChangeItemAPIURL() {
        return null;
    }

    change() {
        let item = this._changeItemFormComponentRef.current.state.item;
        this.props.AppContext.ApiProvider._appContext = this.props.AppContext;
        let api_url = this.getChangeItemAPIURL().replace("{:id}", this.props.ItemId).replace("{:action}", this.props.ChangeAction);
        this.props.AppContext.ApiProvider.send(api_url, 'PATCH',
            this.onChangeItemApiSuccess, 
            this.onChangeItemApiFail,
            null,
            item);
    }

    onChangeItemApiSuccess(result){
        this.props.afterChange(result);
    }
    
    onChangeItemApiFail(error) {
        this.props.onError(error);
    }
}
