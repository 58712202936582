import { NewItemPage } from './../NewItemPage';
import * as ROUTES from '../../routes';
import * as Forms from './../../forms';

export class AccountNewPage extends NewItemPage {
    constructor(props) {
        super(props);

        this._authenticatedPage = true;
        this._title = "PWMS - New Account";

        this._newItemFormControl = Forms.AccountNewForm;
        this._backPage = ROUTES.ACCOUNTS;
        this._afterItemCreatedRoute = ROUTES.ACCOUNTS;
    }

}
