// eslint-disable-next-line
export const devConfig = {
    apiKey: '',
    apiProtocol: 'https',
    apiHost: 'api-pwms-dev.bellamkonda.com',
    key: ''
};

// eslint-disable-next-line
export const testConfig = {
    apiKey: '',
    apiProtocol: 'https',
    apiHost: 'api-pwms-test.bellamkonda.com',
    key: 'oJOOHCMP8mpVdnuWU8kF'
};

// eslint-disable-next-line
export const qaConfig = {
    apiKey: '',
    apiProtocol: 'https',
    apiHost: 'api-pwms-qa.bellamkonda.com',
    key: 'yTasxrHu9YVQNKh0OCDT'
};

// eslint-disable-next-line
export const uatConfig = {
    apiKey: '',
    apiProtocol: 'https',
    apiHost: 'api-pwms-uat.bellamkonda.com',
    key: '8tykiAHWALN9utpzp14p'
};

// eslint-disable-next-line
export const prodConfig = {
    apiKey: '74eHxd2jNm5lkGKwbcpcj9UelqbS3b4w1vdt3J6S',
    apiProtocol: 'https',
    apiHost: 'api-pwms.bellamkonda.com',
    key: 'KVlp85azyoNCDd5vhjyE'
};

// eslint-disable-next-line
export const localConfig = {
    apiKey: '',
    apiProtocol: 'http',
    apiHost: 'localhost:3000',
    key: ''
};

// eslint-disable-next-line
export const dummyConfig = {
    apiKey: '',
    apiProtocol: '',
    apiHost: '',
    key: ''
};

export let config = dummyConfig;
if (process.env.NODE_ENV === 'production') {
    config = prodConfig;
}
else if (process.env.NODE_ENV === 'development') {
    config = localConfig;
}
