import React from 'react';
import { Redirect } from 'react-router';
import * as ROUTES from '../routes';
import { BasePage } from './BasePage';

export class HomePage extends BasePage {
    renderPage() {
      super.renderPage();

      return(
          <Redirect to={ROUTES.DASHBOARD} />
      );
    }
}
