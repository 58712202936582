import React from 'react';
import { Redirect } from 'react-router';
import { AccountForm } from '../../forms';
import { DetailPage } from './../DetailPage';
import * as ROUTES from '../../routes';

export class AccountPage extends DetailPage {
    constructor(props) {
        super(props);

        this._detailItemFormControl = AccountForm;
        this._backPage = ROUTES.ACCOUNTS;

        this._standardToolbarButtons.delete = 'Deactivate';
        this._toolbarItems.push({
            'Icon': 'Pencil',
            'Text': 'Change Password',
            'Action': 'change_password'
        });

        this.changePassword = this.changePassword.bind(this);
    }

    renderPage() {
        const { item_action, item } = this.state;
        if(item_action === "change_password") {
            return(
                <Redirect push from={this.props.location.pathname} to={{
                    pathname: ROUTES.ACCOUNT_CHANGE_ACTION.replace(":id", this._itemId).replace(":action", "change_password"),
                    state: { item: item, referer: this.props.location.pathname }
                }} />
            );
        }

        return super.renderPage();
    }

    onItemLoad(item) {
        this._navbar.current.state.title = item.acc_name;
        this._navbar.current.setState(this._navbar.current.state);
    }

    onItemAction(item, action, params = null) {
        super.onItemAction(item, action, params);

        if(action === "change_password") this.changePassword(item);
    }

    changePassword(item) {
        this.setState({ item: item, item_action: 'change_password' });
    }
}
