import React from 'react';
import { BasePage } from './BasePage';
import { Redirect } from 'react-router';
import * as ROUTES from '../routes';

export class DetailPage extends BasePage {
    _detailItemFormControl = null;
    _backPage = null;
    _detailItemFormControlRef = null;
    _itemId = null;
    _toolbarItems = [];
    _standardToolbarButtons = { 
        delete: '',
        change: ''
    };
    _toolbarIconSize = 36;
    _toolbarIconColor = 'white';

    constructor(props) {
        super(props);

        this._authenticatedPage = true;

        this.state = { 
            item: null,
            error: null,
            back: null,
            toolbarAction: '',
            item_action: ''
        };

        this._detailItemFormControlRef = React.createRef();
        this.onError = this.onError.bind(this);
        this.onItemLoad = this.onItemLoad.bind(this);
        this.onItemAction = this.onItemAction.bind(this);
        this.onToolbarAction = this.onToolbarAction.bind(this);

        this._itemId = this.props.match.params.id;
    }

    preRender() {
        this._navbarLeftButtonIcon = "ArrowLeftShort";
        this._navbarLeftButtonClassName = "btn-warning";
        this._navbarLeftButtonVariant = "warning";
    }

    renderPage() {
        const { back } = this.state;
        if(back !== undefined && back !== null && back !== "") {
            return (
                <Redirect to={back} />
            );
        }

        const { error } = this.state;
        if(error !== null && error !== undefined) {
            return(
                <Redirect push from={this.props.location.pathname} to={{
                    pathname: ROUTES.ERROR,
                    state: { error: error, referer: this.props.location.pathname }
                }} />
            );
        }

        return React.createElement(this._detailItemFormControl, {
            ref: this._detailItemFormControlRef,
            AppContext: this.props.AppContext,
            ItemId: this._itemId,
            onError: this.onError,
            onItemLoad: this.onItemLoad,
            onItemAction: this.onItemAction,
            ToolbarItems: this._toolbarItems,
            StandardToolbarButtons: this._standardToolbarButtons,
            ToolbarIconSize: this._toolbarIconSize,
            ToolbarIconColor: this._toolbarIconColor,
            onToolbarAction: this.onToolbarAction,
            ToolbarActionValue: this.state.toolbarAction
        });
    }

    onNavbarLeftButtonClick(event) {
        super.onNavbarLeftIconClick(event);

        this.state.back = this._backPage;
        if(this.props.location.state !== undefined && this.props.location.state.backPage !== undefined) {
            this.state.back = this.props.location.state.backPage;
        }
        else if(this.props.location.state !== undefined && this.props.location.state.referrer !== undefined) {
            this.state.back = this.props.location.state.referrer;
        }
        this.setState(this.state);
    }

    onError(error) {
        if(error.contains("403")) {
            this.setForbidden();
            return;
        }
        this.setState({ back: '', error: error, item: null, item_created: false });
    }

    onItemLoad(item) {
    }

    onItemAction(item, action, params = null) {
    }

    onToolbarAction(item, action, params = null) {
        this.onItemAction(item, action, params);
    }
}
