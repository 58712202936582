import React from 'react';
import { Form, FormGroup, Container } from '../shared';
import { Company } from '../component';
import * as Config from '../config';

export class CompanyForm extends Form {
    constructor(props) {
        super(props);

        this.state = { 
            isCompanyLoaded: false,
            isCompanyMemosLoaded: false,
            company: null
        };

        this.loadCompany = this.loadCompany.bind(this);
        this.onLoadCompanyApiSuccess = this.onLoadCompanyApiSuccess.bind(this);
        this.onLoadCompanyApiFail = this.onLoadCompanyApiFail.bind(this);
    }

    renderFormComponents() {
        const { company } = this.state;
        if(company !== null) {
            return( 
                <FormGroup className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                    <Container className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                        <Company 
                            AppContext={this.props.AppContext}
                            Company={company} 
                            onMemosCardClick={this.loadCompanyMemos}
                            onCompanyAction={this.onCompanyAction} />
                    </Container>
                </FormGroup>
            );
        }
    }

    onLoadCompanyApiSuccess(result) {
        this.state.isCompanyLoaded = true;
        this.state.isCompanyMemosLoaded = false;
        this.state.company = result.data;
        this.state.company.memos = [];
        this.setState(this.state);
    }

    onLoadCompanyApiFail(error) {
        if(error.contains("403")) {
            this.setForbidden();
            return;
        }
        this.state.isCompanyLoaded = false;
        this.state.isCompanyMemosLoaded = false;
        this.state.company = null;
        super.setError(error);
    }

    loadCompany() {
        const { isCompanyLoaded } = this.state;

        if(isCompanyLoaded) return;

        let url = Config.API_COMPANY_BY_ID;
        url = url.replace("{:id}", this.props.CompanyId);

        this.props.AppContext.ApiProvider._appContext = this.props.AppContext;
        this.props.AppContext.ApiProvider.send(url, 'GET', 
            this.onLoadCompanyApiSuccess, 
            this.onLoadCompanyApiFail);
    }

    componentDidMount() {
        this.loadCompany();
    }

    componentDidUpdate() {
        const { company } = this.state;
        if(company !== null) {
            this.props.onCompanyLoad(company);
        }
    }
}
