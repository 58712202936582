import React from 'react';
import { Redirect } from 'react-router';
import * as ROUTES from '../../routes';
import { ListPage } from '../ListPage';
import * as Forms from './../../forms';

export class FinancialAccountsPage extends ListPage {

    constructor(props) {
        super(props);
        this._title = "Financial Accounts";
        this._listFormControl = Forms.FinancialAccountListForm;
        this._standardToolbarButtons["new"] = false;
        this._listItemMaxTextLength = 30;
    }
    
    renderPage() {
        const { item_id } = this.state;
        if(item_id !== null && item_id !== undefined) {
            return (
                <Redirect from={ROUTES.FINANCIAL_ACCOUNT+':id'} to={ROUTES.FINANCIAL_ACCOUNT+item_id} />
            );
        }

        return super.renderPage();
    }
}
