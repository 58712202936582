import * as SHARED_SERVICE from '../../shared/service';
import LoginUser from './data/LoginUser.json';

export class DummyCookieService extends SHARED_SERVICE.CookieService {
    constructor(props) {
        super(props);

        this.setUser = this.setUser.bind(this);
        this.getUser = this.getUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    getUser() {
        return LoginUser;
    }

    setUser(user) {
        
    }

    removeUser() {

    }
}