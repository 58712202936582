import React from 'react';
import { Form, FormGroup, Container } from '../../shared';
import { UserList } from '../../component';
import * as Config from '../../config';
import * as Pages from '../../pages';

export class UserListForm extends Form {
    constructor(props) {
        super(props);

        this.state = { 
            isUsersLoaded: false,
            users: [],
            error: null,
        };

        this.onLoadUsers = this.onLoadUsers.bind(this);
        this.onLoadUsersApiSuccess = this.onLoadUsersApiSuccess.bind(this);
        this.onLoadUsersApiFail = this.onLoadUsersApiFail.bind(this);
        this.onUserSelect = this.onUserSelect.bind(this);
    }

    renderFormComponents() {
        const { users } = this.state;
        return( 
            <FormGroup className={'w-100 m-0 p-0 ' + this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                <Container className={'w-100 m-0 p-0 ' + this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                    <UserList 
                        users={users} 
                        onClick={this.onUserSelect}
                        AppContext={this.props.AppContext}
                        Active={this.props.Active} />
                </Container>
            </FormGroup>
        );
    }

    onLoadUsersApiSuccess(result) {
        this.state.isUsersLoaded = true;
        this.state.users = result.data;
        this.state.error = null;
        this.setState(this.state);
    }

    onLoadUsersApiFail(error) {
        this.state.isUsersLoaded = false;
        this.state.users = [];
        this.props.Page.setError(error);
    }

    onLoadUsers() {
        const { IsUsersLoaded, error } = this.state;

        if(IsUsersLoaded && error !== null) return;

        let api_url;
        if(typeof(this.props.Page) === "object" && this.props.Page.constructor.name === Pages.LoginPage.name) {
            api_url = Config.API_LOGIN;
        }
        else {
            api_url = Config.API_USER_LIST;
            if(this.props.Active === 'Y') {
                api_url = Config.API_USER_LIST_ACTIVE
            }
        }
        this.props.AppContext.ApiProvider._appContext = this.props.AppContext;
        this.props.AppContext.ApiProvider.send(api_url, 'GET',
            this.onLoadUsersApiSuccess, 
            this.onLoadUsersApiFail);
    }

    componentDidMount() {
        this.onLoadUsers();
    }

    onUserSelect(user_id) {
        this.props.onUserSelected(user_id);
    }
}
