export const API_LOGIN = '/login';
export const API_USER_LIST = '/user/list';
export const API_USER_LIST_ACTIVE = API_USER_LIST + '/active';
export const API_LOGOUT = '/logout';
export const API_ACCOUNT = '/account'
export const API_ACCOUNT_LIST = API_ACCOUNT + '/list';
export const API_ACCOUNT_LIST_ACTIVE = API_ACCOUNT_LIST + '/active';
export const API_ACCOUNT_BY_ID = API_ACCOUNT + '/{:id}'
export const API_ACCOUNT_MEMOS = API_ACCOUNT_BY_ID + '/memos';
export const API_ACCOUNT_CHANGE_ACTION = API_ACCOUNT_BY_ID + '/{:action}'
export const API_SERVICE = '/service'
export const API_SERVICE_LIST = API_SERVICE + '/list';
export const API_SERVICE_LIST_ACTIVE = API_SERVICE_LIST + '/active';
export const API_SERVICE_BY_ID = API_SERVICE + '/{:id}'
export const API_SERVICE_MEMOS = API_SERVICE_BY_ID + '/memos';
export const API_COMPANY_BY_ID = '/company/{:id}'
export const API_DASHBOARD = '/dashboard'
export const API_FINANCIAL_ACCOUNT = '/financial_account'
export const API_FINANCIAL_ACCOUNT_LIST = API_FINANCIAL_ACCOUNT + '/list';
export const API_FINANCIAL_ACCOUNT_LIST_ACTIVE = API_FINANCIAL_ACCOUNT_LIST + '/active';
export const API_FINANCIAL_ACCOUNT_BY_ID = API_FINANCIAL_ACCOUNT + '/{:id}'