import { ListForm } from '../ListForm';
import * as Config from '../../config';
import * as Components from '../../component';

export class ServiceListForm extends ListForm {
    constructor(props) {
        super(props);

        this._listFormComponentName = Components.ServiceList;
        this._listLocalStorageKeyName = "ServiceList";
        this._listAPIURL = Config.API_SERVICE_LIST_ACTIVE;
    }

    getAPIURL() {
        if(this.props.Active !== 'Y')
            return Config.API_SERVICE_LIST;

        return super.getAPIURL()
    }
}
