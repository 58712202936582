import * as SHARED_SERVICE from '../../shared/service';
import AccountList from './data/AccountList.json';

export class DummyLocalStorageService extends SHARED_SERVICE.LocalStorageService {
    constructor(props) {
        super(props);

        this.setItem = this.setItem.bind(this);
        this.getItem = this.getItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    setItem(key, value) {
        
    }

    getItem(key) {
        if(key === 'AccountList') {
            return {
                "Timestamp": (new Date()).toISOString(),
                "data": AccountList.data
            };
        }

        return null;
    }

    removeItem(key) {
        
    }
}