import { ListComponent } from '../ListComponent';

export class FinancialAccountList extends ListComponent {
    constructor(props) {
        super(props);

        this._listItemType = "FinancialAccount";
        this._listItemIdPropName = "id";
        this._listItemNamePropName = "name";
        this._listSearchMinTextLength = 3;
        this._listItemNameCSSClass = "financial-account-list-name";
        this._listItemRightPropName = "outstanding_amt";
    }
}
