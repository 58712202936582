import React from 'react';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CardHeader from 'react-bootstrap/Card';
import CardBody from 'react-bootstrap/Card';
import * as SharedComponent from '../../shared/component';
import * as ROUTES from '../../routes';
import { DetailComponent } from '../DetailComponent';

export class Service extends DetailComponent {
    _accordian = null;

    constructor(props) {
        super(props);
        this._accordion = React.createRef();
    }

    renderComponent() {
        return (
            <SharedComponent.Div className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' m-0 p-0 w-100'}>
                <Accordion defaultActiveKey="service" ref={this._accordion}>
                    <Card className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                        <CardHeader  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' p-1'}>
                            <Accordion.Toggle as={Button} variant="dark" eventKey="service" onClick={(event) => {}}>
                                <SharedComponent.Div className="d-inline-flex flex-fill">Service</SharedComponent.Div>
                                <SharedComponent.Div className="d-inline float-right">
                                    {this._accordion.activeKey === 'service' ? <svg className="bi bi-chevron-bottom" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg> : <svg className="bi bi-chevron-right" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg>}
                                </SharedComponent.Div>
                            </Accordion.Toggle>
                        </CardHeader>
                        <Accordion.Collapse eventKey="service">
                            <CardBody className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                <SharedComponent.Div className="pt-1 pb-1"><span><b>Account:</b>&nbsp;{this.props.item.account_number}</span></SharedComponent.Div>
                                <SharedComponent.Div className="pt-1 pb-1"><span><b>Category:</b>&nbsp;{this.props.item.category.name}&nbsp;<span style={{"fontSize": "10pt", "color": "grey"}}>({this.props.item.category.num_of_services})</span></span></SharedComponent.Div>
                                {(this.props.item.primary_service !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Category:</b>&nbsp;<SharedComponent.Link to={{ pathname: ROUTES.SERVICE+this.props.item.primary_service.id, state: { backPage: ROUTES.SERVICE+this.props.item.id }}}>{this.props.item.primary_service.name}</SharedComponent.Link></span></SharedComponent.Div> : "")}
                                <SharedComponent.Div className="pt-1 pb-1"><span><b>Company:</b>&nbsp;<SharedComponent.Link to={{ pathname: ROUTES.COMPANY+this.props.item.company.id, state: { backPage: ROUTES.SERVICE+this.props.item.id }}}>{this.props.item.company.name}</SharedComponent.Link></span></SharedComponent.Div>
                                {(this.props.item.account !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Account:</b>&nbsp;<SharedComponent.Link to={{ pathname: ROUTES.ACCOUNT+this.props.item.account.acc_id, state: { backPage: ROUTES.SERVICE+this.props.item.id }}}>{this.props.item.account.acc_name}</SharedComponent.Link></span></SharedComponent.Div> : "")}
                            </CardBody>
                        </Accordion.Collapse>
                    </Card>
                    {
                        (this.props.item.contact_address !== null || this.props.item.contact_email !== null || this.props.item.contact_phone_1 !== null || this.props.item.contact_phone_2 !== null || this.props.item.contact_phone_3 !== null ?
                            <Card className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                <CardHeader  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' p-1'}>
                                    <Accordion.Toggle as={Button} variant="dark" eventKey="contact" onClick={(event) => {}}>
                                        <SharedComponent.Div className="d-inline-flex flex-fill">Contact</SharedComponent.Div>
                                        <SharedComponent.Div className="d-inline float-right">
                                            {this._accordion.activeKey === 'contact' ? <svg className="bi bi-chevron-bottom" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg> : <svg className="bi bi-chevron-right" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg>}
                                        </SharedComponent.Div>
                                    </Accordion.Toggle>
                                </CardHeader>
                                <Accordion.Collapse eventKey="contact">
                                    <CardBody className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                        {(this.props.item.contact_address !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Address:</b>&nbsp;{this.props.item.contact_address.line_1+", "+(this.props.item.contact_address.unit_type !== null ? this.props.item.contact_address.unit_type+" "+this.props.item.contact_address.unit_id+", " : "")+(this.props.item.contact_address.line_2 !== null ? this.props.item.contact_address.line_2+", " : "")+this.props.item.contact_address.city+", "+this.props.item.contact_address.state.name+", "+this.props.item.contact_address.country.name+" "+this.props.item.contact_address.zip_pin_code+(this.props.item.contact_address.zip_ext !== null ? "-"+this.props.item.contact_address.zip_ext : "")}</span></SharedComponent.Div> : "")}
                                        {(this.props.item.contact_email !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>E-mail:</b>&nbsp;{this.props.item.contact_email}</span></SharedComponent.Div> : "")}
                                        {(this.props.item.contact_phone_1 !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Phone 1:</b>&nbsp;{this.props.item.contact_phone_1}</span></SharedComponent.Div> : "")}
                                        {(this.props.item.contact_phone_2 !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Phone 2:</b>&nbsp;{this.props.item.contact_phone_2}</span></SharedComponent.Div> : "")}
                                        {(this.props.item.contact_phone_3 !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Phone 3:</b>&nbsp;{this.props.item.contact_phone_3}</span></SharedComponent.Div> : "")}
                                    </CardBody>
                                </Accordion.Collapse>
                            </Card>
                        : "" )
                    }
                    {
                        (this.props.item.bill !== null ? 
                            <Card className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                <CardHeader  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' p-1'}>
                                    <Accordion.Toggle as={Button} variant="dark" eventKey="bill" onClick={(event) => {}}>
                                        <SharedComponent.Div className="d-inline-flex flex-fill">Bill</SharedComponent.Div>
                                        <SharedComponent.Div className="d-inline float-right">
                                            {this._accordion.activeKey === 'bill' ? <svg className="bi bi-chevron-bottom" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg> : <svg className="bi bi-chevron-right" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg>}
                                        </SharedComponent.Div>
                                    </Accordion.Toggle>
                                </CardHeader>
                                <Accordion.Collapse eventKey="bill">
                                    <CardBody className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Effective:</b>&nbsp;{this.props.item.bill.eff_dt}{(this.props.item.bill.exp_dt !== null ? "&nbsp;-&nbsp;"+this.props.item.bill.exp_dt : "")}</span></SharedComponent.Div>
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Type:</b>&nbsp;{this.props.item.bill.billing_type_text}</span></SharedComponent.Div>
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Frequency:</b>&nbsp;{this.props.item.bill.freq_code_name}</span></SharedComponent.Div>
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Cycle:</b>&nbsp;{this.props.item.bill.cycle}</span></SharedComponent.Div>
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Current Cycle:</b>&nbsp;{this.props.item.bill.current_cycle_begin_date}&nbsp;-&nbsp;{this.props.item.bill.current_cycle_end_date}</span></SharedComponent.Div>
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Next Cycle:</b>&nbsp;{this.props.item.bill.next_cycle_begin_date}&nbsp;-&nbsp;{this.props.item.bill.next_cycle_end_date}</span></SharedComponent.Div>
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Name:</b>&nbsp;{this.props.item.bill.name}</span></SharedComponent.Div>
                                        {(this.props.item.bill.secondary_name !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Secondary Name:</b>&nbsp;{this.props.item.bill.secondary_name}</span></SharedComponent.Div> : "")}
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Address:</b>&nbsp;{this.props.item.bill.address.line_1+", "+(this.props.item.bill.address.unit_type !== null ? this.props.item.bill.address.unit_type+" "+this.props.item.bill.address.unit_id+", " : "")+(this.props.item.bill.address.line_2 !== null ? this.props.item.bill.address.line_2+", " : "")+this.props.item.bill.address.city+", "+this.props.item.bill.address.state.name+", "+this.props.item.bill.address.country.name+" "+this.props.item.bill.address.zip_pin_code+(this.props.item.bill.address.zip_ext !== null ? "-"+this.props.item.bill.address.zip_ext : "")}</span></SharedComponent.Div>
                                        {(this.props.item.bill.current_due_date !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Current Due:</b>&nbsp;{this.props.item.bill.current_due_date}</span></SharedComponent.Div> : "")}
                                        {(this.props.item.bill.next_due_date !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Next Due:</b>&nbsp;{this.props.item.bill.next_due_date}</span></SharedComponent.Div> : "")}
                                        {(this.props.item.bill.email !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>E-mail:</b>&nbsp;{this.props.item.bill.email}</span></SharedComponent.Div> : "")}
                                        {(this.props.item.bill.phone_1 !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Phone 1:</b>&nbsp;{this.props.item.bill.phone_1}</span></SharedComponent.Div> : "")}
                                        {(this.props.item.bill.phone_2 !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Phone 2:</b>&nbsp;{this.props.item.bill.phone_2}</span></SharedComponent.Div> : "")}
                                        {(this.props.item.bill.phone_3 !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Phone 3:</b>&nbsp;{this.props.item.bill.phone_3}</span></SharedComponent.Div> : "")}
                                    </CardBody>
                                </Accordion.Collapse>
                            </Card>
                        : "" )
                    }
                    {
                        (this.props.item.payment_plan !== null ? 
                            <Card className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                <CardHeader  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' p-1'}>
                                    <Accordion.Toggle as={Button} variant="dark" eventKey="payment_plan" onClick={(event) => {}}>
                                        <SharedComponent.Div className="d-inline-flex flex-fill">Payment Plan</SharedComponent.Div>
                                        <SharedComponent.Div className="d-inline float-right">
                                            {this._accordion.activeKey === 'payment_plan' ? <svg className="bi bi-chevron-bottom" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg> : <svg className="bi bi-chevron-right" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg>}
                                        </SharedComponent.Div>
                                    </Accordion.Toggle>
                                </CardHeader>
                                <Accordion.Collapse eventKey="payment_plan">
                                    <CardBody className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Effective:</b>&nbsp;{this.props.item.payment_plan.eff_dt}{(this.props.item.payment_plan.exp_dt !== null ? "&nbsp;-&nbsp;"+this.props.item.payment_plan.exp_dt : "")}</span></SharedComponent.Div>
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Cycle Code:</b>&nbsp;{this.props.item.payment_plan.cycle_code}</span></SharedComponent.Div>
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Funding Source:</b>&nbsp;{this.props.item.payment_plan.funding_source.financial_account.name}</span></SharedComponent.Div>
                                        {(this.props.item.payment_plan.funding_source_address_change_action_code !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Address Change Action:</b>&nbsp;{this.props.item.payment_plan.funding_source_address_change_action_code}</span></SharedComponent.Div> : "")}
                                    </CardBody>
                                </Accordion.Collapse>
                            </Card>
                        : "" )
                    }
                    {
                        (this.props.item.renewal_method !== null ? 
                            <Card className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                <CardHeader  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' p-1'}>
                                    <Accordion.Toggle as={Button} variant="dark" eventKey="renewal" onClick={(event) => {}}>
                                        <SharedComponent.Div className="d-inline-flex flex-fill">Renewal</SharedComponent.Div>
                                        <SharedComponent.Div className="d-inline float-right">
                                            {this._accordion.activeKey === 'renewal' ? <svg className="bi bi-chevron-bottom" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg> : <svg className="bi bi-chevron-right" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg>}
                                        </SharedComponent.Div>
                                    </Accordion.Toggle>
                                </CardHeader>
                                <Accordion.Collapse eventKey="renewal">
                                    <CardBody className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Method:</b>&nbsp;{this.props.item.renewal_method_text}</span></SharedComponent.Div>
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Term Period:</b>&nbsp;{this.props.item.renewal_term_period}</span></SharedComponent.Div>
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Term Period Type:</b>&nbsp;{this.props.item.renewal_term_period_type_text}</span></SharedComponent.Div>
                                        <SharedComponent.Div className="pt-1 pb-1"><span><b>Action:</b>&nbsp;{this.props.item.renewal_action_code_text}</span></SharedComponent.Div>
                                    </CardBody>
                                </Accordion.Collapse>
                            </Card>
                        : "" )
                    }
                    {
                        (this.props.item.remarks !== null ? 
                            <Card className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                <CardHeader  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' p-1'}>
                                    <Accordion.Toggle as={Button} variant="dark" eventKey="remarks" onClick={(event) => {}}>
                                        <SharedComponent.Div className="d-inline-flex flex-fill">Remarks</SharedComponent.Div>
                                        <SharedComponent.Div className="d-inline float-right">
                                            {this._accordion.activeKey === 'remarks' ? <svg className="bi bi-chevron-bottom" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg> : <svg className="bi bi-chevron-right" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg>}
                                        </SharedComponent.Div>
                                    </Accordion.Toggle>
                                </CardHeader>
                                <Accordion.Collapse eventKey="remarks">
                                    <CardBody className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                        {this.props.item.remarks}
                                    </CardBody>
                                </Accordion.Collapse>
                            </Card>
                        : "" )
                    }
                </Accordion>
            </SharedComponent.Div>
        );
    }
};
