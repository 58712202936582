import * as SHARED_SERVICE from '../shared/service';

export class CookieService extends SHARED_SERVICE.CookieService {
    _userCookieName = "BKD_PWMS_User";

    constructor(props) {
        super(props);

        this.setUser = this.setUser.bind(this);
        this.getUser = this.getUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    getUser() {
        return this.get(this._userCookieName, { path : '/'});
    }

    setUser(user) {
        this.set(this._userCookieName, user, { path : '/'});
    }

    removeUser() {
        this.remove(this._userCookieName, { path : '/'});
    }
}