import React from 'react';
import { Redirect } from 'react-router';
import { CompanyForm } from '../forms';
import { BasePage } from './BasePage';
import * as ROUTES from '../routes';

export class CompanyPage extends BasePage {
    _companyId = null;

    constructor(props) {
        super(props);

        this._authenticatedPage = true;

        this.state = { 
            back: null
        };

        this.onCompanyLoad = this.onCompanyLoad.bind(this);

        this._companyId = this.props.match.params.id;

    }

    preRender() {
        if(this.props.location.state !== undefined && this.props.location.state.backPage !== undefined) {
            this._navbarLeftButtonIcon = "ArrowLeftShort";
            this._navbarLeftButtonClassName = "btn-warning";
            this._navbarLeftButtonVariant = "warning";
        } else {
            this._navbarLeftButtonIcon = "House";
            this._navbarLeftButtonClassName = "btn-info";
            this._navbarLeftButtonVariant = "info";
        }
    }

    renderPage() {
        super.renderPage();

        const { back } = this.state;
        if(back !== undefined && back !== null && back !== '') {
            return (
                <Redirect to={back} />
            );
        }

        return( 
            <CompanyForm 
                AppContext={this.props.AppContext} 
                CompanyId={this._companyId}
                onCompanyLoad={this.onCompanyLoad} />
        );
    }

    onCompanyLoad(company) {
        this._navbar.current.state.title = company.name;
        this._navbar.current.setState(this._navbar.current.state);
    }

    onNavbarLeftButtonClick(event) {
        super.onNavbarLeftIconClick(event);

        this.state.back = ROUTES.HOME;
        if(this.props.location.state !== undefined && this.props.location.state.backPage !== undefined) {
            this.state.back = this.props.location.state.backPage;
        }
        this.setState(this.state);
    }
}
