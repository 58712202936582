import React from 'react';
import BaseComponent from './BaseComponent.ts';
import { Div } from './Div';

export class Component extends BaseComponent {

    render() {
        return (
            <Div 
                style={this.props.style}
                className={this.props.className}>
                {this.renderComponent()}
            </Div>
        );
    }

    renderComponent() {
    }
}