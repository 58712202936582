import * as SHARED_PROVIDER from '../shared/provider';
import { DummyApiService } from '../service/dummy/DummyApiService';

export class ApiProvider extends SHARED_PROVIDER.ApiProvider {
    _appContext = null;

    constructor(apiService) {
        if(apiService === null || process.env.NODE_ENV === 'test')
            apiService = new DummyApiService();

        super(apiService);
    }

    send(url, method, onsuccess, onfail, headers=null, data=null) {
        this.apiService._appContext = this._appContext;

        super.send(url, method, onsuccess, onfail, headers, data);
    }

    sendAsync(url, method, headers=null, data=null) {
        this.apiService._appContext = this._appContext;

        super.sendAsync(url, method, headers, data);
    }
}