import React from 'react';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CardHeader from 'react-bootstrap/Card';
import CardBody from 'react-bootstrap/Card';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as Util from '../../util';
import * as SharedComponent from '../../shared/component';
import * as Config from '../../config';
import { DetailComponent } from '../DetailComponent';
import * as ROUTES from '../../routes';

export class FinancialAccount extends DetailComponent {
    _accordian = null;

    constructor(props) {
        super(props);
        this._accordion = React.createRef();
    }

    preToolbarRender() {
        if(this.props.item.deactivated_on !== null) {
            if(this.props.StandardToolbarButtons !== null && this.props.StandardToolbarButtons !== undefined) {
                if(this.props.StandardToolbarButtons.delete !== undefined && this.props.StandardToolbarButtons.delete !== null) {
                    this.props.StandardToolbarButtons.delete = '';
                }
                if(this.props.StandardToolbarButtons.change !== undefined && this.props.StandardToolbarButtons.change !== null) {
                    this.props.StandardToolbarButtons.change = '';
                }
            }
        }
    }

    renderComponent() {
        return (
            <SharedComponent.Div className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' m-0 p-0 w-100'}>
                {this.renderToolbar()}
                <Accordion defaultActiveKey="finacc" ref={this._accordion}>
                    <Card  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                        <CardHeader  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' p-1'}>
                            <Accordion.Toggle as={Button} variant="dark" eventKey="finacc" onClick={(event) => {}}>
                                <SharedComponent.Div className="d-inline-flex flex-fill">Financial Account</SharedComponent.Div>
                                <SharedComponent.Div className="d-inline float-right">
                                    {this._accordion.activeKey === 'finacc' ? <svg className="bi bi-chevron-bottom" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg> : <svg className="bi bi-chevron-right" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg>}
                                </SharedComponent.Div>
                            </Accordion.Toggle>
                        </CardHeader>
                        <Accordion.Collapse eventKey="finacc">
                            <CardBody  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                <SharedComponent.Div className="container">
                                    <span><b>Account Type:</b>&nbsp;{this.props.item.account_type}</span>
                                </SharedComponent.Div>
                                <SharedComponent.Div className="container">
                                    <span>
                                        <b>Account #</b>&nbsp;
                                        <CopyToClipboard text={this.props.item.service.account_number}>
                                            <SharedComponent.Button variant="link">
                                                <span>{this.props.item.service.account_number}</span>
                                            </SharedComponent.Button>
                                        </CopyToClipboard>
                                    </span>
                                </SharedComponent.Div>
                                <SharedComponent.Div className="container">
                                    {
                                        (this.props.item.cvv !== null ? 
                                            <CopyToClipboard text={Util.Crypt.decrypt(this.props.item.cvvHash, Config.config.key)}>
                                                <SharedComponent.Button variant="outline-dark">
                                                    <SharedComponent.Image 
                                                        src={"data:image/"+Config.credentialImageConfig.image_format+";base64,"+this.props.item.cvv} 
                                                        alt={"CVV for "+this.props.item.name}
                                                        width={parseInt(window.innerWidth * Config.credentialImageConfig.width_percent / 100)}
                                                        height={Config.credentialImageConfig.height}
                                                        style={{ "overflow": "hidden" }}>
                                                    </SharedComponent.Image>
                                                </SharedComponent.Button>
                                            </CopyToClipboard>
                                            : "")
                                    }
                                </SharedComponent.Div>
                                <SharedComponent.Div className="container">
                                    {
                                        (this.props.item.transaction_password !== null ? 
                                            <CopyToClipboard text={Util.Crypt.decrypt(this.props.item.transaction_passwordHash, Config.config.key)}>
                                                <SharedComponent.Button variant="outline-dark">
                                                    <SharedComponent.Image 
                                                        src={"data:image/"+Config.credentialImageConfig.image_format+";base64,"+this.props.item.transaction_password} 
                                                        alt={"Transaction password for "+this.props.item.name}
                                                        width={parseInt(window.innerWidth * Config.credentialImageConfig.width_percent / 100)}
                                                        height={Config.credentialImageConfig.height}
                                                        style={{ "overflow": "hidden" }}>
                                                    </SharedComponent.Image>
                                                </SharedComponent.Button>
                                            </CopyToClipboard>
                                            : "")
                                    }
                                </SharedComponent.Div>
                                <SharedComponent.Div className="container">
                                    {
                                        (this.props.item.pin !== null ? 
                                            <CopyToClipboard text={Util.Crypt.decrypt(this.props.item.pinHash, Config.config.key)}>
                                                <SharedComponent.Button variant="outline-dark">
                                                    <SharedComponent.Image 
                                                        src={"data:image/"+Config.credentialImageConfig.image_format+";base64,"+this.props.item.pin} 
                                                        alt={"PIN password for "+this.props.item.name}
                                                        width={parseInt(window.innerWidth * Config.credentialImageConfig.width_percent / 100)}
                                                        height={Config.credentialImageConfig.height}
                                                        style={{ "overflow": "hidden" }}>
                                                    </SharedComponent.Image>
                                                </SharedComponent.Button>
                                            </CopyToClipboard>
                                            : "")
                                    }
                                </SharedComponent.Div>
                                <SharedComponent.Div className="container">
                                    {
                                        (this.props.item.exp_month !== null && this.props.item.exp_year !== null ? 
                                            <span><b>Expires:</b>&nbsp;{this.props.item.exp_month}&nbsp;/&nbsp;{this.props.item.exp_year}</span>
                                            : "")
                                    }
                                </SharedComponent.Div>
                                <SharedComponent.Div className="container">
                                    {(this.props.item.service !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Service:</b>&nbsp;<SharedComponent.Link to={{ pathname: ROUTES.SERVICE+this.props.item.service.id, state: { backPage: ROUTES.FINANCIAL_ACCOUNT+this.props.item.id }}}>{this.props.item.service.name}</SharedComponent.Link></span></SharedComponent.Div> : "")}
                                </SharedComponent.Div>
                                <SharedComponent.Div className="container">
                                    {(this.props.item.service.account !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Account:</b>&nbsp;<SharedComponent.Link to={{ pathname: ROUTES.ACCOUNT+this.props.item.service.account.acc_id, state: { backPage: ROUTES.FINANCIAL_ACCOUNT+this.props.item.id }}}>{this.props.item.service.account.acc_name}</SharedComponent.Link></span></SharedComponent.Div> : "")}
                                </SharedComponent.Div>
                            </CardBody>
                        </Accordion.Collapse>
                    </Card>
                    {
                        ((this.props.item.account_type === 'CC' || this.props.item.account_type === 'DC') && (this.props.item.issuer !== null) ?
                            <Card className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                <CardHeader  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' p-1'}>
                                    <Accordion.Toggle as={Button} variant="dark" eventKey="issuer" onClick={(event) => {}}>
                                        <SharedComponent.Div className="d-inline-flex flex-fill">Issuer</SharedComponent.Div>
                                        <SharedComponent.Div className="d-inline float-right">
                                            {this._accordion.activeKey === 'issuer' ? <svg className="bi bi-chevron-bottom" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg> : <svg className="bi bi-chevron-right" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg>}
                                        </SharedComponent.Div>
                                    </Accordion.Toggle>
                                </CardHeader>
                                <Accordion.Collapse eventKey="issuer">
                                    <CardBody className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                        <SharedComponent.Div className="container">
                                            <span><b>Code:</b>&nbsp;{this.props.item.issuer.code}{(this.props.item.issuer.issuer_logo_file_name_32 !== null ? <span>&nbsp;<SharedComponent.Image src={'/Content/Icons/' + this.props.item.issuer.issuer_logo_file_name_32 + '.png'} width="32px" height="32px" /></span> : "")}</span>
                                        </SharedComponent.Div>
                                        <SharedComponent.Div className="container">
                                            <SharedComponent.Div className="pt-1 pb-1"><span><b>Company:</b>&nbsp;<SharedComponent.Link to={{ pathname: ROUTES.COMPANY+this.props.item.issuer.company.id, state: { backPage: ROUTES.FINANCIAL_ACCOUNT+this.props.item.id }}}>{this.props.item.issuer.company.name}</SharedComponent.Link></span></SharedComponent.Div>
                                        </SharedComponent.Div>
                                    </CardBody>
                                </Accordion.Collapse>
                            </Card>
                            : "")
                    }
                    {
                        (this.props.item.account_type === 'CC' || this.props.item.account_type === 'DC' ?
                            <Card className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                <CardHeader  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' p-1'}>
                                    <Accordion.Toggle as={Button} variant="dark" eventKey="cardholder" onClick={(event) => {}}>
                                        <SharedComponent.Div className="d-inline-flex flex-fill">Contact</SharedComponent.Div>
                                        <SharedComponent.Div className="d-inline float-right">
                                            {this._accordion.activeKey === 'cardholder' ? <svg className="bi bi-chevron-bottom" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg> : <svg className="bi bi-chevron-right" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg>}
                                        </SharedComponent.Div>
                                    </Accordion.Toggle>
                                </CardHeader>
                                <Accordion.Collapse eventKey="cardholder">
                                    <CardBody className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                        <SharedComponent.Div className="container">
                                            <SharedComponent.Div><b>Name:</b>&nbsp;{this.props.item.card_holder_name}</SharedComponent.Div>
                                            <SharedComponent.Div>
                                                {
                                                    (this.props.item.authorized_user_1 !== null ?
                                                        <span><b>Name:</b>&nbsp;{this.props.item.authorized_user_1}</span>
                                                    : "")
                                                }
                                            </SharedComponent.Div>
                                            <SharedComponent.Div>
                                                {
                                                    (this.props.item.authorized_user_2 !== null ?
                                                        <span><b>Name:</b>&nbsp;{this.props.item.authorized_user_2}</span>
                                                    : "")
                                                }
                                            </SharedComponent.Div>
                                            <SharedComponent.Div>
                                                {
                                                    (this.props.item.authorized_user_3 !== null ?
                                                        <span><b>Name:</b>&nbsp;{this.props.item.authorized_user_3}</span>
                                                    : "")
                                                }
                                            </SharedComponent.Div>
                                            <SharedComponent.Div>
                                                {
                                                    (this.props.item.authorized_user_4 !== null ?
                                                        <span><b>Name:</b>&nbsp;{this.props.item.authorized_user_4}</span>
                                                    : "")
                                                }
                                            </SharedComponent.Div>
                                            <SharedComponent.Div>
                                                {
                                                    (this.props.item.authorized_user_5 !== null ?
                                                        <span><b>Name:</b>&nbsp;{this.props.item.authorized_user_5}</span>
                                                    : "")
                                                }
                                            </SharedComponent.Div>
                                        </SharedComponent.Div>
                                    </CardBody>
                                </Accordion.Collapse>
                            </Card>
                        : "")
                    }
                </Accordion>
            </SharedComponent.Div>
        );
    }
}
