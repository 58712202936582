import axios from 'axios';
import promise from 'promise';

// Add a request interceptor 
export var axiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent 
  return config;
}, function (error) {
   // Do something with request error 
   return promise.reject(error);
});
