import * as Config from '../config';
import * as SHARED_SERVICE from '../shared/service';

export class ApiService extends SHARED_SERVICE.ApiService {
    _appContext = null;
    onCallerSendSuccess = null;
    onCallerSendFail = null;

    constructor(axiosInstance = null) {
        super(axiosInstance)

        this.onSendSuccess = this.onSendSuccess.bind(this);
        this.onSendFail = this.onSendFail.bind(this);
    }

    generateHeaders(user_id = null, session_token = null) {
        return {
          'X-Bkd-Pwms-Activity-Timestamp': (new Date()).toISOString(),
          'X-Bkd-Pwms-App-Code': Config.ApplicationCode,
          'X-Bkd-Pwms-User-Id': (this._appContext === null || this._appContext.User === null || this._appContext.User === undefined || this._appContext.User.user_id === null || this._appContext.User.user_id === undefined ? user_id : this._appContext.User.user_id),
          'Authorization': (this._appContext === null || this._appContext.User === null || this._appContext.User === undefined || this._appContext.User.session_token === null || this._appContext.User.session_token === undefined ? session_token : this._appContext.User.session_token)
        };
    }

    setHeaders(headers, user_id = null, session_token = null) {
        if (this._appContext !== null && this._appContext.User === null && this._appContext.CookieProvider !== null) {
            this._appContext.User = this._appContext.CookieProvider.getUser();
        }
        headers['X-Bkd-Pwms-Activity-Timestamp'] = (new Date()).toISOString();
        headers['X-Bkd-Pwms-App-Code'] = Config.ApplicationCode;
        headers['X-Bkd-Pwms-User-Id'] = (this._appContext === null || this._appContext.User === null || this._appContext.User === undefined || this._appContext.User.user_id === null || this._appContext.User.user_id === undefined ? user_id : this._appContext.User.user_id);
        headers['Authorization'] = (this._appContext === null || this._appContext.User === null || this._appContext.User === undefined || this._appContext.User.session_token === null || this._appContext.User.session_token === undefined ? session_token : this._appContext.User.session_token);
    }

    onSendSuccess(result) {
        if(result.errors !== null && result.errors.length > 0) {
            this.onCallerSendFail(result.errors.join('<br />'));
        }
        else {
            this.onCallerSendSuccess(result);
        }
    }

    onSendFail(error) {
        this.onCallerSendFail(error);
    }

    send(path, method, onsuccess, onfail, headers=null, data=null) {
        if (headers === null)
            headers = this.generateHeaders();
        else
            this.setHeaders(headers);

        this.onCallerSendSuccess = onsuccess;
        this.onCallerSendFail = onfail;

        if(this._axiosInstance !== null) {
            super.send(
                path, 
                method, 
                Config.config.apiKey, 
                this.onSendSuccess, this.onSendFail, 
                headers, 
                data
            );
        }
        else {
            super.send(
                Config.config.apiProtocol + '://' + Config.config.apiHost + path, 
                method, 
                Config.config.apiKey, 
                this.onSendSuccess, this.onSendFail, 
                headers, 
                data
            );
        }
    }

    sendAsync(path, method, headers=null, data=null) {
        if (headers === null)
            headers = this.generateHeaders();
        else
            this.setHeaders(headers);

        if(this._axiosInstance !== null) {
            super.sendAsync(
                path, 
                method, 
                Config.config.apiKey, 
                headers, 
                data
            );
        }
        else {
            super.sendAsync(
                Config.config.apiProtocol + '://' + Config.config.apiHost + path, 
                method, 
                Config.config.apiKey, 
                headers, 
                data
            );
        }
    }
}