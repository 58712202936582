import { ListComponent } from '../ListComponent';

export class ServiceList extends ListComponent {
    constructor(props) {
        super(props);

        this._listItemType = "Service";
        this._listItemIdPropName = "id";
        this._listItemNamePropName = "name";
        this._listSearchMinTextLength = 3;
        this._listItemNameCSSClass = "service-list-name";
        this._listItemSecondLinePropName = "account_number"
    }
}
