import React from 'react';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CardHeader from 'react-bootstrap/Card';
import CardBody from 'react-bootstrap/Card';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as Util from '../../util';
import * as SharedComponent from '../../shared/component';
import * as Config from '../../config';
import { DetailComponent } from '../DetailComponent';

export class Account extends DetailComponent {
    _accordian = null;

    constructor(props) {
        super(props);
        this._accordion = React.createRef();
    }

    preToolbarRender() {
        if(this.props.item.deactivated_on !== null) {
            if(this.props.StandardToolbarButtons !== null && this.props.StandardToolbarButtons !== undefined) {
                if(this.props.StandardToolbarButtons.delete !== undefined && this.props.StandardToolbarButtons.delete !== null) {
                    this.props.StandardToolbarButtons.delete = '';
                }
                if(this.props.StandardToolbarButtons.change !== undefined && this.props.StandardToolbarButtons.change !== null) {
                    this.props.StandardToolbarButtons.change = '';
                }
            }

            var changePasswordToolbarItem = this._toolbarItems.find(ti => ti['Action'] === 'change_password');
            if(changePasswordToolbarItem !== null) {
                this._toolbarItems.pop(changePasswordToolbarItem);
            }
        }
    }

    renderComponent() {
        return (
            <SharedComponent.Div className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' m-0 p-0 w-100'}>
                {this.renderToolbar()}
                <a href={this.props.item.url} target="_blank" rel="noopener noreferrer">{Config.LinkTextMaxLength > 0 && this.props.item.url.length > Config.LinkTextMaxLength ? this.props.item.url.substring(0, (Config.LinkTextMaxLength - 1))+'...' : this.props.item.url}</a>
                <Accordion defaultActiveKey="cred" ref={this._accordion}>
                    <Card  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                        <CardHeader  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' p-1'}>
                            <Accordion.Toggle as={Button} variant="dark" eventKey="cred" onClick={(event) => {}}>
                                <SharedComponent.Div className="d-inline-flex flex-fill">Credentials</SharedComponent.Div>
                                <SharedComponent.Div className="d-inline float-right">
                                    {this._accordion.activeKey === 'cred' ? <svg className="bi bi-chevron-bottom" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg> : <svg className="bi bi-chevron-right" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg>}
                                </SharedComponent.Div>
                            </Accordion.Toggle>
                        </CardHeader>
                        <Accordion.Collapse eventKey="cred">
                            <CardBody  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                <CopyToClipboard text={this.props.item.cred.user_name}>
                                    <SharedComponent.Button variant="link">
                                        <span>{this.props.item.cred.user_name}</span>
                                    </SharedComponent.Button>
                                </CopyToClipboard>
                                <CopyToClipboard text={Util.Crypt.decrypt(this.props.item.cred.passwordHash, Config.config.key)}>
                                    <SharedComponent.Button variant="outline-dark">
                                        <SharedComponent.Image 
                                            src={"data:image/"+Config.credentialImageConfig.image_format+";base64,"+this.props.item.cred.password} 
                                            alt={"password for "+this.props.item.cred.user_name}
                                            width={parseInt(window.innerWidth * Config.credentialImageConfig.width_percent / 100)}
                                            height={Config.credentialImageConfig.height}
                                            style={{ "overflow": "hidden" }}>
                                        </SharedComponent.Image>
                                    </SharedComponent.Button>
                                </CopyToClipboard>
                            </CardBody>
                        </Accordion.Collapse>
                    </Card>
                    {
                        (this.props.item.memos !== undefined ?
                            <Card  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                <CardHeader  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' p-1'}>
                                    <Accordion.Toggle as={Button} variant="dark" eventKey="memo" onClick={(event) => { this.props.onMemosCardClick(event); }}>
                                        <SharedComponent.Div className="d-inline-flex flex-fill">Memo</SharedComponent.Div>
                                        <SharedComponent.Div className="d-inline float-right">
                                            {this._accordion.activeKey === 'memo' ? <svg className="bi bi-chevron-bottom" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg> : <svg className="bi bi-chevron-right" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg>}
                                        </SharedComponent.Div>
                                    </Accordion.Toggle>
                                </CardHeader>
                                <Accordion.Collapse eventKey="memo">
                                    <CardBody  className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                                        {
                                            this.props.item.memos.map((e, i) =>
                                                <SharedComponent.Div className="d-flex">
                                                    <SharedComponent.Div className="d-flex-fill text-left">
                                                        <p className="m-0 pl-3 p-0 w-100 text-left">{e["memo_text"]}</p>
                                                    </SharedComponent.Div>
                                                </SharedComponent.Div>
                                            ) 
                                        }
                                    </CardBody>
                                </Accordion.Collapse>
                            </Card>
                        : "")
                    }
                </Accordion>
            </SharedComponent.Div>
        );
    }
}
