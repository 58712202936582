import { ListForm } from '../ListForm';
import * as Config from '../../config';
import * as Components from '../../component';
import * as ROUTES from '../../routes';

export class AccountListForm extends ListForm {
    constructor(props) {
        super(props);

        this._listFormComponentName = Components.AccountList;
        this._listLocalStorageKeyName = "AccountList";
        this._listAPIURL = Config.API_ACCOUNT_LIST_ACTIVE;
        this._newItemRouteName = ROUTES.ACCOUNT_NEW;
    }

    getAPIURL() {
        if(this.props.Active !== 'Y')
            return Config.API_ACCOUNT_LIST;

        return super.getAPIURL()
    }
}
