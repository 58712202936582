import { ServiceForm } from '../../forms';
import { DetailPage } from './../DetailPage';
import * as ROUTES from '../../routes';

export class ServicePage extends DetailPage {
    constructor(props) {
        super(props);

        this._detailItemFormControl = ServiceForm;
        this._backPage = ROUTES.SERVICES;
    }

    onItemLoad(item) {
        this._navbar.current.state.title = item.name;
        this._navbar.current.setState(this._navbar.current.state);
    }

    onItemAction(item_id, action, params = null) {
    }
}
