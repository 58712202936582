import React from 'react';
import * as SharedComponent from '../../shared/component';
import { ChangeItemComponent } from '../ChangeItemComponent';

export class AccountChangePassword extends ChangeItemComponent {
    constructor(props) {
        super(props);

        this.state = { 
            item: {
                cred_password: '',
                cred_password_confirm: ''
            }
        };
    }

    renderComponent() {
        return (
            <SharedComponent.Div className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' m-0 p-0 w-100'}>
                {this.props.Item.acc_name}
                <div style={{"display": (this.state.errorMessage === null ? "none" : "block")}} className="error-message">{this.state.errorMessage}</div>
                <SharedComponent.Div className="input-label">Password:</SharedComponent.Div>
                <SharedComponent.Div className="input"><input type="password" name="cred_password" value={this.state.item.cred_password} onChange={this.onChange}></input></SharedComponent.Div>
                <SharedComponent.Div className="input-label">Confirm Password:</SharedComponent.Div>
                <SharedComponent.Div className="input"><input type="password" name="cred_password_confirm" value={this.state.item.cred_password_confirm} onChange={this.onChange}></input></SharedComponent.Div>
            </SharedComponent.Div>
        );
    }

    validate() {
        if(!super.validate()) return false;

        const { item } = this.state;

        if(item.cred_password.trim() === "") {
            this.setState({ errorMessage: "Password is required"});
            return false;
        }
        else if(item.cred_password_confirm.trim() === "") {
            this.setState({ errorMessage: "Please confirm password"});
            return false;
        }
        else if(item.cred_password !== item.cred_password_confirm) {
            this.setState({ errorMessage: "Passwords should match"});
            return false;
        }

        this.setState({ errorMessage: null });

        return true;
    }
};
