import * as Components from '../../component';
import { NewItemForm } from '../NewItemForm';
import * as Config from '../../config';
import * as ROUTES from '../../routes';

export class AccountNewForm extends NewItemForm {
    constructor(props) {
        super(props);

        this._newItemFormComponentName = Components.AccountNew;
        this._dataLocalStorageKeyName = "AllUserList";
        this._loadData = true;
        this._afterItemCreatedRoute = ROUTES.ACCOUNTS;
        this._newItemApiSchemaURL = "https://schema.bellamkonda.com/json/pwms/api/v1/Account.json";
    }

    getDataAPIURL() {
        return Config.API_USER_LIST;
    }

    getCreateAPIURL() {
        return Config.API_ACCOUNT;
    }
}
