import React from 'react';
import BootStrapCol from 'react-bootstrap/Col';

export const Col = (props) => {
    return (
        <BootStrapCol 
            sm={props.sm}
            md={props.md}
            lg={props.lg}
            xl={props.xl}
            style={props.style}
            className={props.className}>
            {props.children}
        </BootStrapCol>
    );
}

