import React from 'react';
import { Redirect } from 'react-router';
import * as ROUTES from '../../routes';
import { ListPage } from '../ListPage';
import * as Forms from './../../forms';

export class AccountsPage extends ListPage {

    constructor(props) {
        super(props);
        this._title = "Accounts";
        this._listFormControl = Forms.AccountListForm;
    }
    
    renderPage() {
        const { item_id } = this.state;
        if(item_id !== null && item_id !== undefined) {
            return (
                <Redirect from={ROUTES.ACCOUNT+':id'} to={ROUTES.ACCOUNT+item_id} />
            );
        }

        return super.renderPage();
    }
}
