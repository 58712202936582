import React from 'react';
import { BasePage } from './BasePage';
import { Redirect } from 'react-router';
import * as ROUTES from '../routes';

export class NewItemPage extends BasePage {
    _newItemFormControl = null;
    _backPage = null;
    _newItemFormControlRef = null;
    _afterItemCreatedRoute = null;

    constructor(props) {
        super(props);

        this.state = { 
            item: null,
            error: null,
            item_created: false,
            back: null
        };

        this._newItemFormControlRef = React.createRef();
        this.onItemCreated = this.onItemCreated.bind(this);
        this.onError = this.onError.bind(this);
    }

    preRender() {
        this._navbarLeftButtonText = "Cancel";
        this._navbarLeftButtonClassName = "btn-danger";
        this._navbarLeftButtonVariant = "danger";

        this._navbarRightButtonIcon = null;
        this._navbarRightButtonText = "Create";
        this._navbarRightButtonClassName = "btn-primary";
        this._navbarRightButtonVariant = "primary";
        this._navbarRightButtonType = "submit";
    }

    renderPage() {
        const { back } = this.state;
        if(back !== undefined && back !== null && back !== "") {
            return (
                <Redirect to={back} />
            );
        }

        const { error } = this.state;
        if(error !== null && error !== undefined) {
            return(
                <Redirect push from={this.props.location.pathname} to={{
                    pathname: ROUTES.ERROR,
                    state: { error: error, referer: this.props.location.pathname }
                }} />
            );
        }

        const { item_created } = this.state;
        if(item_created) {
            return(
                <Redirect push from={this.props.location.pathname} to={{
                    pathname: this._afterItemCreatedRoute,
                    state: { referer: this.props.location.pathname }
                }} />
            );
        }

        return React.createElement(this._newItemFormControl, {
            ref: this._newItemFormControlRef,
            AppContext: this.props.AppContext,
            onItemCreated: this.onItemCreated,
            onError: this.onError
        });
    }

    onNavbarLeftButtonClick(event) {
        super.onNavbarLeftIconClick(event);

        this.state.back = this._backPage;
        if(this.props.location.state !== undefined && this.props.location.state.backPage !== undefined) {
            this.state.back = this.props.location.state.backPage;
        }
        else if(this.props.location.state !== undefined && this.props.location.state.referrer !== undefined) {
            this.state.back = this.props.location.state.referrer;
        }
        this.setState(this.state);
    }

    onNavbarRightButtonClick(event) {
        super.onNavbarRightIconClick(event);

        if(this._newItemFormControlRef.current.onCreate() !== 0) return;

        this._newItemFormControlRef.current.create();
    }

    onItemCreated(item) {
        this.setState({ back: '', error: null, item: item, item_created: true });
    }

    onError(error) {
        if(error.contains("403")) {
            this.setForbidden();
            return;
        }
        this.setState({ back: '', error: error, item: null, item_created: false });
    }
}
