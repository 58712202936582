import React from 'react';
import * as SharedComponent from '../../shared/component';
import { NewItemComponent } from '../NewItemComponent';

export class AccountNew extends NewItemComponent {
    constructor(props) {
        super(props);

        this.state = { 
            item: {
                acc_name: '',
                url: '',
                username: '',
                cred_password: '',
                cred_password_confirm: '',
                assigned_user: ''
            }
        };
    }

    renderComponent() {
        return (
            <SharedComponent.Div className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' m-0 p-0 w-100'}>
                <div style={{"display": (this.state.errorMessage === null ? "none" : "block")}} className="error-message">{this.state.errorMessage}</div>
                <SharedComponent.Div className="input-label input-label-first">Name:</SharedComponent.Div>
                <SharedComponent.Div className="input"><input type="text" name="acc_name" value={this.state.item.acc_name} onChange={this.onChange}></input></SharedComponent.Div>
                <SharedComponent.Div className="input-label">URL:</SharedComponent.Div>
                <SharedComponent.Div className="input"><input type="text" name="url" value={this.state.item.url} onChange={this.onChange}></input></SharedComponent.Div>
                <SharedComponent.Div className="input-label">Username:</SharedComponent.Div>
                <SharedComponent.Div className="input"><input type="text" name="username" value={this.state.item.username} onChange={this.onChange}></input></SharedComponent.Div>
                <SharedComponent.Div className="input-label">Password:</SharedComponent.Div>
                <SharedComponent.Div className="input"><input type="password" name="cred_password" value={this.state.item.cred_password} onChange={this.onChange}></input></SharedComponent.Div>
                <SharedComponent.Div className="input-label">Confirm Password:</SharedComponent.Div>
                <SharedComponent.Div className="input"><input type="password" name="cred_password_confirm" value={this.state.item.cred_password_confirm} onChange={this.onChange}></input></SharedComponent.Div>
                <SharedComponent.Div className="input-label">Assigned User:</SharedComponent.Div>
                <SharedComponent.Div className="input input-last">
                    <select name="assigned_user" value={this.state.item.assigned_user} onChange={this.onChange}>
                        <option key="__blank__" name=""></option>
                        {
                            this.props.data.map((e, i) => 
                                <option key={e.user_id} value={e.user_id}>{e.short_name}</option>
                            )
                        }
                    </select>
                </SharedComponent.Div>
            </SharedComponent.Div>
        );
    }

    validate() {
        if(!super.validate()) return false;

        const { item } = this.state;

        if(item.acc_name.trim() === "") {
            this.setState({ errorMessage: "Account Name is required"});
            return false;
        }
        else if(item.url.trim() === "") {
            this.setState({ errorMessage: "URL is required"});
            return false;
        }
        else if(item.username.trim() === "") {
            this.setState({ errorMessage: "User Name is required"});
            return false;
        }
        else if(item.cred_password.trim() === "") {
            this.setState({ errorMessage: "Password is required"});
            return false;
        }
        else if(item.cred_password_confirm.trim() === "") {
            this.setState({ errorMessage: "Please confirm password"});
            return false;
        }
        else if(item.cred_password !== item.cred_password_confirm) {
            this.setState({ errorMessage: "Passwords should match"});
            return false;
        }

        this.setState({ errorMessage: null });

        return true;
    }
};
