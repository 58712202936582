import { FinancialAccountForm } from '../../forms';
import { DetailPage } from './../DetailPage';
import * as ROUTES from '../../routes';

export class FinancialAccountPage extends DetailPage {
    constructor(props) {
        super(props);

        this._detailItemFormControl = FinancialAccountForm;
        this._backPage = ROUTES.FINANCIAL_ACCOUNTS;

        this._standardToolbarButtons.delete = 'Terminate';
    }


    onItemLoad(item) {
        this._navbar.current.state.title = item.name;
        this._navbar.current.setState(this._navbar.current.state);
    }
}
