import React from 'react';
import { BasePage } from './BasePage';
import { Redirect } from 'react-router';
import * as ROUTES from '../routes';

export class ChangeItemPage extends BasePage {
    _changeItemFormControl = null;
    _backPage = null;
    _changeItemFormControlRef = null;
    _itemId = null;
    _changeAction = null;
    _changeButtonName = "Change";
    _afterChangeRoute = null;
    
    constructor(props) {
        super(props);

        this._authenticatedPage = true;

        this.state = { 
            changed: false,
            error: null,
            back: null,
            item: this.props.location.state.item
        };

        this._changeItemFormControlRef = React.createRef();
        this.onError = this.onError.bind(this);
        this.afterChange = this.afterChange.bind(this);

        this._itemId = this.props.match.params.id;
        this._changeAction = this.props.match.params.action;
    }

    preRender() {
      this._navbarLeftButtonText = "Cancel";
      this._navbarLeftButtonClassName = "btn-danger";
      this._navbarLeftButtonVariant = "danger";

      this._navbarRightButtonIcon = null;
      this._navbarRightButtonText = this._changeButtonName;
      this._navbarRightButtonClassName = "btn-success";
      this._navbarRightButtonVariant = "success";
      this._navbarRightButtonType = "submit";
    }

    renderPage() {
        const { back } = this.state;
        if(back !== undefined && back !== null && back !== "") {
            return (
                <Redirect to={back} />
            );
        }

        const { changed } = this.state;
        if(changed !== undefined && changed !== null && changed) {
            return (
                <Redirect to={this._afterChangeRoute} />
            );
        }

        const { error } = this.state;
        if(error !== null && error !== undefined) {
            return(
                <Redirect push from={this.props.location.pathname} to={{
                    pathname: ROUTES.ERROR,
                    state: { error: error, referer: this.props.location.pathname }
                }} />
            );
        }

        return React.createElement(this._changeItemFormControl, {
            ref: this._changeItemFormControlRef,
            AppContext: this.props.AppContext,
            ItemId: this._itemId,
            Item: this.state.item,
            ChangeAction: this._changeAction,
            onError: this.onError,
            afterChange: this.afterChange
        });
    }

    onNavbarLeftButtonClick(event) {
        super.onNavbarLeftIconClick(event);

        this.state.back = this._backPage;
        if(this.props.location.state !== undefined && this.props.location.state.backPage !== undefined) {
            this.state.back = this.props.location.state.backPage;
        }
        else if(this.props.location.state !== undefined && this.props.location.state.referrer !== undefined) {
            this.state.back = this.props.location.state.referrer;
        }
        this.setState(this.state);
    }

    onNavbarRightButtonClick(event) {
        super.onNavbarLeftIconClick(event);

        this._changeItemFormControlRef.current.onChange(event);
    }

    onError(error) {
        if(error.contains("403")) {
            this.setForbidden();
            return;
        }
        this.setState({ back: '', error: error, changed: false });
    }

    afterChange() {
        this.setState({ changed: true, back: '', error: null });
    }
}
