import { CookieService } from '../service';

export class CookieProvider {
    cookieService = null;

    constructor (cookieService) {
        if (cookieService === null)
            cookieService = new CookieService();

        this.cookieService = cookieService;

        this.set = this.set.bind(this);
        this.get = this.get.bind(this);
        this.remove = this.remove.bind(this);
    }

    set (name, value, options = {}) {
        this.cookieService.set(name, value, options);
    }

    get (name, options = {}) {
        return this.cookieService.get(name);
    }

    remove (name, options = {}) {
        this.cookieService.remove(name);
    }

}