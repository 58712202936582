import { ListComponent } from '../ListComponent';

export class AccountList extends ListComponent {
    constructor(props) {
        super(props);

        this._listItemType = "Account";
        this._listItemIdPropName = "acc_id";
        this._listItemNamePropName = "acc_name";
        this._listSearchMinTextLength = 3;
        this._listItemNameCSSClass = "account-list-name";
    }
}
