import React from 'react';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CardHeader from 'react-bootstrap/Card';
import CardBody from 'react-bootstrap/Card';
import * as SharedComponent from '../shared/component';

export const Company = (props) => { 
    let accordion = React.createRef();

    return (
        <SharedComponent.Div className={props.AppContext.Theme.bg + ' ' + props.AppContext.Theme.txt + ' m-0 p-0 w-100'}>
            <Accordion defaultActiveKey="company" ref={accordion}>
                <Card className={props.AppContext.Theme.bg + ' ' + props.AppContext.Theme.txt}>
                    <CardHeader  className={props.AppContext.Theme.bg + ' ' + props.AppContext.Theme.txt + ' p-1'}>
                        <Accordion.Toggle as={Button} variant="dark" eventKey="company" onClick={(event) => {}}>
                            <SharedComponent.Div className="d-inline-flex flex-fill">Service</SharedComponent.Div>
                            <SharedComponent.Div className="d-inline float-right">
                                {accordion.activeKey === 'company' ? <svg className="bi bi-chevron-bottom" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg> : <svg className="bi bi-chevron-right" width="16" height="16" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clipRule="evenodd"/></svg>}
                            </SharedComponent.Div>
                        </Accordion.Toggle>
                    </CardHeader>
                    <Accordion.Collapse eventKey="company">
                        <CardBody className={props.AppContext.Theme.bg + ' ' + props.AppContext.Theme.txt}>
                            <SharedComponent.Div className="pt-1 pb-1"><span><b>Address:</b>&nbsp;{props.Company.address.line_1+", "+(props.Company.address.unit_type !== null ? props.Company.address.unit_type+" "+props.Company.address.unit_id+", " : "")+(props.Company.address.line_2 !== null ? props.Company.address.line_2+", " : "")+props.Company.address.city+", "+props.Company.address.state.name+", "+props.Company.address.country.name+" "+props.Company.address.zip_pin_code+(props.Company.address.zip_ext !== null ? "-"+props.Company.address.zip_ext : "")}</span></SharedComponent.Div>
                            <SharedComponent.Div className="pt-1 pb-1"><span><b>Phone:</b>&nbsp;{props.Company.primary_phone}</span></SharedComponent.Div>
                            {(props.Company.mobile_website !== null ? <SharedComponent.Div className="pt-1 pb-1"><a href={"http://"+props.Company.mobile_website} target="_blank" rel="noopener noreferrer">{props.Company.mobile_website}</a></SharedComponent.Div> : <SharedComponent.Div className="pt-1 pb-1"><a href={"http://"+props.Company.website} target="_blank" rel="noopener noreferrer">{props.Company.website}</a></SharedComponent.Div>)}
                            {(props.Company.primary_fax !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Fax:</b>&nbsp;{props.Company.primary_fax}</span></SharedComponent.Div> : "")}
                            {(props.Company.primary_email !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>E-mail:</b>&nbsp;{props.Company.primary_email}</span></SharedComponent.Div> : "")}
                            {(props.Company.working_days !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Working Days:</b>&nbsp;{props.Company.working_days}</span></SharedComponent.Div> : "")}
                            {(props.Company.working_hours !== null ? <SharedComponent.Div className="pt-1 pb-1"><span><b>Working Hours:</b>&nbsp;{props.Company.working_hours}</span></SharedComponent.Div> : "")}
                        </CardBody>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </SharedComponent.Div>
    )
};
