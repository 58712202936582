import React from 'react';
import BaseComponent from './BaseComponent.ts';
import ReactBootStrapNavbar from 'react-bootstrap/Navbar';

export class Navbar extends BaseComponent {
    render() {
        return (
            <ReactBootStrapNavbar className={this.props.className}
                style={this.props.style}>
                {this.props.children}
            </ReactBootStrapNavbar>
        );
    }
}
