import React from 'react';
import ReactBootStrapNavbar from 'react-bootstrap/Navbar';
import * as SharedComponent from '../shared/component';
import * as Icon from 'react-bootstrap-icons';

export class Toolbar extends SharedComponent.Toolbar {
  
    render() {
        let iconColor = this.props.IconColor || 'inherit';
        let iconSize = this.props.IconSize+'px' || '36px';
        let buttonIndex = 0;
        let actionFn = this.props.Action;
        let actionVal = this.props.ActionValue;

        if(this.props.Items === null || this.props.Items.length <= 0) return("");

        return (
            <SharedComponent.Container className='m-0 p-0 w-100 pt-1 pb-4'>
                <ReactBootStrapNavbar 
                    className={'m-0 p-0 w-100'}
                    style={this.props.style}>
                    <SharedComponent.Row className='m-0 p-0 w-100' style={{"height": this.props.IconSize+'px' || '36px'}}>
                    {
                        this.props.Items.map(function(item) {
                            let ButtonIconTag = Icon[item['Icon']];
                            buttonIndex++;
                            return (
                                <SharedComponent.Col id={'col-'+buttonIndex} key={'col-'+buttonIndex} className="col m-0 p-0">
                                    <SharedComponent.Button 
                                        id={'button-'+buttonIndex} 
                                        className="m-0 p-0" variant="outline" 
                                        onClick={(event) => { event.preventDefault(); actionVal = item['Action']; actionFn(item['Action']);}}>
                                        <SharedComponent.Div className="m-0 p-0"><ButtonIconTag size={iconSize} color={iconColor} /></SharedComponent.Div>
                                        {
                                            (item.Text !== undefined && item.Text !== null && item.Text !== '' ? 
                                            <SharedComponent.Div className="p-0" style={{"color": iconColor, "fontSize": "small", "margin": "0 0 0 0", "marginTop": "-5px"}}>{item.Text}</SharedComponent.Div>
                                            : "")
                                        }
                                    </SharedComponent.Button>
                                </SharedComponent.Col>
                            );
                        })
                    }
                    </SharedComponent.Row>
                </ReactBootStrapNavbar>
            </SharedComponent.Container>
        );
    }
}
