import React from 'react';
import BootStrapRow from 'react-bootstrap/Row';

export const Row = (props) => {
    return (
        <BootStrapRow
            style={props.style}
            className={props.className}>
            {props.children}
        </BootStrapRow>
    );
}
