import React from 'react';
import { Dashboard } from '../component';
import { Form } from "../shared";
import * as Config from '../config';

export class DashboardForm extends Form {
    constructor(props, ref) {
        super(props);

        this.state = { 
            tiles: null,
            error: null
        }

        this.getAPIURL = this.getAPIURL.bind(this);
        this.loadTiles = this.loadTiles.bind(this);
        this.onLoadTilesApiSuccess = this.onLoadTilesApiSuccess.bind(this);
        this.onLoadTilesApiFail = this.onLoadTilesApiFail.bind(this);
    }

    renderFormComponents() {
        const { tiles } = this.state;

        if(tiles === null) {
            return;
        }

        return(
            <Dashboard AppContext={this.props.AppContext} Tiles={tiles} />
        );
    }

    onLoadTilesApiSuccess(result) {
        this.state.tiles = result.data;
        this.state.error = null;
        this.setState(this.state);
    }

    onLoadTilesApiFail(error) {
        if(error.contains("403")) {
            this.setForbidden();
            return;
        }
        this.state.tiles = [];
        this.state.error = error;
        this.setState(this.state);
    }

    getAPIURL() {
        return Config.API_DASHBOARD;
    }

    loadTiles() {
        const { tiles, error } = this.state;

        if(tiles !== null || error !== null) return;
    
        this.props.AppContext.ApiProvider._appContext = this.props.AppContext;
        let api_url = this.getAPIURL();
        this.props.AppContext.ApiProvider.send(api_url, 'GET',
            this.onLoadTilesApiSuccess, 
            this.onLoadTilesApiFail);
    }

    componentDidMount() {
        this.loadTiles();
    }
}
