import React from 'react';
import { Form, FormGroup, Container } from "../shared";

export class DetailForm extends Form {
    _detailItemFormComponentName = null;
    _detailItemFormComponentRef = null;
    _toolbarItems = [];
    _standardToolbarButtons = { 
        delete: '',
        change: ''
    };
    _toolbarIconSize = 36;
    _toolbarIconColor = 'white';
    _listLocalStorageKeyName = null;

    constructor(props, ref) {
        super(props);

        this.state = { 
            item: null,
            toolbarAction: ''
        };

        this._detailItemFormComponentRef = React.createRef();
        this.getItemAPIURL = this.getItemAPIURL.bind(this);
        this.loadItem = this.loadItem.bind(this);
        this.onLoadItemApiSuccess = this.onLoadItemApiSuccess.bind(this);
        this.onLoadItemApiFail = this.onLoadItemApiFail.bind(this);
        this.onItemAction = this.onItemAction.bind(this);
        this.onToolbarAction = this.onToolbarAction.bind(this);
        this.delete = this.delete.bind(this);
        this.change = this.change.bind(this);
        this.getDeleteItemAPIURL = this.getDeleteItemAPIURL.bind(this);
        this.onDeleteItemApiSuccess = this.onDeleteItemApiSuccess.bind(this);
        this.onDeleteItemApiFail = this.onDeleteItemApiFail.bind(this);

        this._toolbarItems = this.props.ToolbarItems || null;
        if(this.props.StandardToolbarButtons !== undefined && this.props.StandardToolbarButtons !== null){
            if(this.props.StandardToolbarButtons.delete === undefined || this.props.StandardToolbarButtons.delete === null) {
                this._standardToolbarButtons.delete = '';
            }
            else {
                this._standardToolbarButtons.delete = this.props.StandardToolbarButtons.delete;
            }

            if(this.props.StandardToolbarButtons.change === undefined || this.props.StandardToolbarButtons.change === null) {
                this._standardToolbarButtons.change = '';
            }
            else {
                this._standardToolbarButtons.change = this.props.StandardToolbarButtons.change;
            }
        }
        else {
            this._standardToolbarButtons = null;
        }
        this._toolbarIconSize = this.props.ToolbarIconSize || 36;
        this._toolbarIconColor = this.props.ToolarIconColor || 'white';
    }

    renderFormComponents() {
        const { item } = this.state;
        if(item === null) return;
        return (
            <FormGroup className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                <Container className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' m-0 p-0 w-100'}>
                {
                    React.createElement(this._detailItemFormComponentName, {
                        ref: this._detailItemFormComponentRef,
                        AppContext: this.props.AppContext,
                        item: item,
                        ToolbarItems: this._toolbarItems,
                        onToolbarAction: this.onToolbarAction,
                        ToolbarActionValue: this.state.toolbarAction,
                        StandardToolbarButtons: this._standardToolbarButtons,
                        ToolbarIconSize: this._toolbarIconSize,
                        ToolbarIconColor: this._toolbarIconColor
                    })
                }
                </Container>
            </FormGroup>
        );
    }

    getItemAPIURL() {
        return null;
    }
    
    loadItem() {
        const { item } = this.state;

        if(item !== null) return;

        this.props.AppContext.ApiProvider._appContext = this.props.AppContext;
        let api_url = this.getItemAPIURL();
        this.props.AppContext.ApiProvider.send(api_url, 'GET',
            this.onLoadItemApiSuccess, 
            this.onLoadItemApiFail);
    }
    
    onLoadItemApiSuccess(result){
        this.state.item = result.data;
        this.setState(this.state);
    }
    
    onLoadItemApiFail(error) {
        this.props.onError(error);
    }
    
    componentDidMount() {
        this.loadItem();
    }

    componentDidUpdate() {
        const { item } = this.state;
        if(item !== null) {
            this.props.onItemLoad(item);
        }
    }

    onItemAction(item, action, params = null) {
        this.props.onItemAction(item, action, params);
    }

    change(property, new_value) {
        const { item } = this.state;

        if(item === null) return;
    }

    getDeleteItemAPIURL() {
        return null;
    }

    onDeleteItemApiSuccess(result){
        if(this._listLocalStorageKeyName !== null && this._listLocalStorageKeyName !== undefined) {
            this.props.AppContext.LocalStorageProvider.removeItem(this._listLocalStorageKeyName);
        }
        this.loadItem();
    }
    
    onDeleteItemApiFail(error) {
        this.props.onError(error);
    }

    delete() {
        const { item } = this.state;

        if(item === null) return;

        this.props.AppContext.ApiProvider._appContext = this.props.AppContext;
        let api_url = this.getDeleteItemAPIURL();
        this.props.AppContext.ApiProvider.send(api_url, 'DELETE',
            this.onDeleteItemApiSuccess, 
            this.onDeleteItemApiFail);
    }

    onToolbarAction(action, params = null) {
        let { item } = this.state;
        this.setState({ toolbarAction: action });
        if(action === 'delete') this.delete();
        else if(action === 'change') this.change();
        this.props.onToolbarAction(item, action, params);
    }
}
