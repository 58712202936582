import React from 'react';
import { BaseForm } from './BaseForm.ts';
import BootStrapForm from 'react-bootstrap/Form';

export class FormGroup extends BaseForm {
    render() {
        return(
            <BootStrapForm.Group
                className={this.props.className}
                style={this.props.style}>
                {this.props.children}
            </BootStrapForm.Group>
        );
    }
}
