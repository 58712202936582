import React from 'react';
import { BasePage } from './BasePage';
import * as Config from '../config';

export class ListPage extends BasePage {
    _listFormControl = null;
    _listItemMaxTextLength = Config.ListItemTextMaxLength;
    _toolbarItems = [];
    _standardToolbarButtons = { 
        refresh: true, 
        new: true
    };
    _toolbarIconSize = 36;
    _toolbarIconColor = 'white';

    constructor(props) {
        super(props);

        this.state = { 
            item_id: null,
            toolbarAction: ''
        };

        this.onItemSelect = this.onItemSelect.bind(this);
        this.onItemsSearch = this.onItemsSearch.bind(this);
        this.onToolbarAction = this.onToolbarAction.bind(this);
    }

    renderPage() {
        return React.createElement(this._listFormControl, {
            AppContext: this.props.AppContext,
            onSelect: this.onItemSelect,
            onSearch: this.onItemsSearch,
            ListItemTextMaxLength: this._listItemMaxTextLength,
            ToolbarItems: this._toolbarItems,
            StandardToolbarButtons: this._standardToolbarButtons,
            ToolbarIconSize: this._toolbarIconSize,
            ToolbarIconColor: this._toolbarIconColor,
            onToolbarAction: this.onToolbarAction,
            ToolbarActionValue: this.state.toolbarAction
        });
    }
    
    onItemSelect(id) {
        this.state.item_id = id;
        this.setState(this.state);
    }

    onItemsSearch(e) {

    }

    onToolbarAction(action) {

    }
}
