import React from 'react';
import ReactRouterDOMLink from 'react-router-dom/Link';

export const Link = (props) => {
    return (
        <ReactRouterDOMLink to={props.to} 
            className={props.className}
            style={props.style}
            onClick={props.onClick}>
            {props.children}
        </ReactRouterDOMLink>
    );
}
