import React from 'react';
import * as SharedComponent from '../shared/component';
import { Container, Row, Col } from 'react-bootstrap';
/* eslint-disable no-unused-vars */
import { Jumbotron } from 'react-bootstrap';
/* eslint-enable no-unused-vars */
import * as ROUTES from '../routes';

export class Dashboard extends SharedComponent.Component {
    _tileRowCounter = 0;
    _tileCounter = 0;

    constructor(props) {
        super(props);

        this._renderTileRow = this._renderTileRow.bind(this);
        this._renderTile = this._renderTile.bind(this);
        this._renderTileTitle = this._renderTileTitle.bind(this);
        this._renderTileContent = this._renderTileContent.bind(this);
    }

    _renderTileTitle(tile) {
        let title_text_color = (tile.title_text_color !== null ? "text-"+tile.title_text_color : this.props.AppContext.Theme.txt);
        let title_background = (tile.title_background || "transparent");
        return(
            <Container fluid className="w-100 m-0 p-0">
                {
                    (tile.title !== null ? 
                        <div 
                            style={{ fontSize: "larger", top: "0", left: "0" }} 
                            className={"position-absolute m-1 font-weight-bold "+title_text_color+" bg-"+title_background}>
                            {tile.title}
                        </div>
                        : 
                        ""
                    )
                }
            </Container>
        );
    }

    _renderTileContent(tile) {
        return(
            <Container fluid className={"m-0 mt-3 w-100 p-0 align-middle text-center"}>
            {
                (tile.content_type === "HTML" ? 
                    <div dangerouslySetInnerHTML={{ __html: tile.content }} />
                    : 
                    tile.content
                )
            }
            </Container>
        );
    }

    _renderTile(tile) {
        let tile_background = (tile.background || "transparent");
        let tile_text_color = (tile.text_color !== null ? "text-"+tile.text_color : this.props.AppContext.Theme.txt);

        return(
            <Col key={"tile-"+(this._tileRowCounter)+"-"+(++this._tileCounter)} 
                {...(tile.width > 0 && tile.width < 12 ? {xs: tile.width} : { xs: true })}
                className={"m-0 p-2 border border-primary rounded bg-"+tile_background+" "+tile_text_color}>
                {
                    <Container fluid className="w-100 m-0 p-0">
                    {
                        (tile.link_to !== null ?
                            <SharedComponent.Link 
                                to={ROUTES[tile.link_to]} 
                                style={{ color: "inherit", textDecoration: "inherit", backgroundColor: "inherit" }}>
                                <Container fluid className="w-100 m-0 p-0">
                                {
                                    this._renderTileTitle(tile)
                                }
                                {
                                    this._renderTileContent(tile)
                                }
                                </Container>
                            </SharedComponent.Link>
                        :
                            <Container fluid className="w-100 m-0 p-0">
                            {
                                this._renderTileTitle(tile)
                            }
                            {
                                this._renderTileContent(tile)
                            }
                            </Container>
                        )
                    }
                    </Container>
                }
            </Col>
        );
    }

    _renderTileRow(tileRow) {
        return (
            <Row key={"tile-row-"+(++this._tileRowCounter)} xs={12} className={"m-1 p-0"}>
            {
                tileRow.map((t) =>
                    this._renderTile(t)
                )
            }
        </Row>
        );
    }

    renderComponent() {
        return (
            <Container style={{ fontSize: "small" }} fluid="md" className={"m-0 p-0"}>
                {
                    this.props.Tiles.map((tr) => 
                        this._renderTileRow(tr)
                    )
                }
            </Container>
        );
    }
}
