import * as SHARED_PROVIDER from '../shared/provider';
import { DummyCookieService } from '../service/dummy/DummyCookieService';

export class CookieProvider extends SHARED_PROVIDER.CookieProvider {
    constructor(cookieService) {
        if(cookieService === null || process.env.NODE_ENV === 'test')
            cookieService = new DummyCookieService();

        super(cookieService);

        this.setUser = this.setUser.bind(this);
        this.getUser = this.getUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    getUser() {
        let user = this.cookieService.getUser();

        if(user !== null && user !== undefined) {
            //let tokenExpire = user.session_token_expires;
            //let tokenExpireDateTime = Date.parse(tokenExpire);
            //if(tokenExpireDateTime < Date.UTC()) {
            //    return null;
            //}
        }

        return user;
    }

    setUser(user) {
        this.cookieService.setUser(user);
    }

    removeUser() {
        this.cookieService.removeUser();
    }
}