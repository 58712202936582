import Cookies from 'universal-cookie';

export class CookieService {
    _cookies = new Cookies();

    constructor(props) {
        this.set = this.set.bind(this);
        this.get = this.get.bind(this);
        this.remove = this.remove.bind(this);
    }

    set (name, value, options = {}) {
        this._cookies.set(name, value, options);
    }

    get (name, options = {}) {
        return this._cookies.get(name, options);
    }

    remove (name, options = {}) {
        this._cookies.remove(name, options);
    }
}