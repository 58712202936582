import React from 'react';

export const Div = (props) => {
    return (
        <div id={props.id} 
            className={props.className}
            style={props.style}>
            {props.children}
        </div>
    );
}
