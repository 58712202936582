import React from 'react';
import { ListGroup } from 'react-bootstrap';
import * as SharedComponent from '../../shared/component';

export const UserList = (props) => (
    <ListGroup variant="flush" className={'m-0 ml-2 mr-2 p-0 w-100 ' + props.AppContext.Theme.bg + ' ' + props.AppContext.Theme.txt}>
        {
            props.users.map((e, i) =>
                <ListGroup.Item key={e.user_id} action 
                    onClick={(event) => { event.preventDefault(); props.onClick(e.short_name); } }
                    className={'w-100 m-0 p-0 align-middle ' + props.AppContext.Theme.bg + ' ' + props.AppContext.Theme.txt}>
                        <SharedComponent.Div className="d-flex">
                            <SharedComponent.Div className="d-inline user-list-profile">
                                <SharedComponent.Image className="img-fluid m-0 p-0 ml-0" src={props.gender === 'F' ? '/Content/Icons/User_Female.png' : '/Content/Icons/User_Male.png'} alt='user profile icon by gender' />
                            </SharedComponent.Div>
                            <SharedComponent.Div className="d-inline-flex flex-fill user-list-name">
                                <p className="ml-2">{e.short_name}</p>
                            </SharedComponent.Div>
                        </SharedComponent.Div>
                </ListGroup.Item>
            )
        }
    </ListGroup>
);
