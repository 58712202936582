import React from 'react';
import { Redirect } from 'react-router';
import { BasePage } from './BasePage';
import * as SharedComponent from './../shared';
import * as ROUTES from '../routes';

export class ErrorPage extends BasePage {
    constructor(props) {
        super(props);

        this.state = {
            redirectToReferer: false,
            redirectToHome: false
        }

        this._title = "Error";
        this._authenticatedPage = false;
    }

    preRender() {
        this._navbarLeftButtonIcon = "ArrowLeftShort";
        this._navbarLeftButtonIconColor = "white";
        this._navbarLeftButtonClassName = "btn-danger";
        this._navbarLeftButtonVariant = "danger";

        this._navbarRightButtonIcon = "House";
        this._navbarRightButtonClassName = "btn-info";
        this._navbarRightButtonVariant = "info";
    }

    renderPage() {
        super.renderPage();

        const { redirectToHome } = this.state;
        if(redirectToHome) {
            return(
                <Redirect to={ROUTES.HOME} />
            );
        }

        const { redirectToReferer } = this.state;
        if(redirectToReferer) {
            const { referer } = this.props.location.state;
            return(
                <Redirect to={referer} />
            );
        }

        const { error } = this.props.location.state;
        let error_msg;
        if(Array.isArray(error)) {
            error_msg = error.join('<br />');
        }
        else if(typeof error === "object") {
            error_msg = error.toString();
        }
        else {
            error_msg = error;
        }

        return( 
            <SharedComponent.Div>
                {error_msg}
            </SharedComponent.Div>
        );
    }

    onNavbarLeftButtonClick(event) {
        event.preventDefault();

        this.state.redirectToReferer = true;
        this.state.redirectToHome = false;
        this.setState(this.state);
    }

    onNavbarRightButtonClick(event) {
        event.preventDefault();

        this.state.redirectToReferer = false;
        this.state.redirectToHome = true;
        this.setState(this.state);
    }
}
