import { Service } from '../../component';
import * as Config from '../../config';
import { DetailForm } from '../DetailForm';

export class ServiceForm extends DetailForm {
    constructor(props) {
        super(props);

        this._detailItemFormComponentName = Service;
    }

    getItemAPIURL() {
        let url = Config.API_SERVICE_BY_ID;
        url = url.replace("{:id}", this.props.ItemId);
        return url;
    }
}
