import React from 'react';
import { Redirect } from 'react-router';
import * as SharedComponent from './../shared';
import * as Component from './../component';
import * as ROUTES from '../routes';

export class BasePage extends SharedComponent.BasePage {
    _authenticatedPage = true;
    _isAuthenticated = false;
    _title = "PWMS";
    _navbarLeftIcon = null;
    _navbarRightIcon = null;
    _navbarLeftClassName = null;
    _navbarRightClassName = null;
    _navbarLeftButtonIcon = null;
    _navbarRightButtonIcon = null;
    _navbarLeftButtonIconColor = null;
    _navbarRightButtonIconColor = null;
    _navbarLeftButtonIconSize = null;
    _navbarRightButtonIconSize = null;
    _navbarLeftButtonText = null;
    _navbarRightButtonText = null;
    _navbarLeftButtonClassName = null;
    _navbarRightButtonClassName = null;
    _navbarLeftButtonVariant = null;
    _navbarRightButtonVariant = null;
    _navbarLeftButtonType = "button";
    _navbarRightButtonType = "button";
    _navbar = null;
    _hideMenu = false;
    _titleLink = null;

    constructor(props) {
        super(props);

        this.state = {
            logout: false,
            error: null,
            forbidden: false,
            dashboard: false,
            user_dashboard: false
        };

        this.renderPage = this.renderPage.bind(this);
        this.onNavbarLeftIconClick = this.onNavbarLeftIconClick.bind(this);
        this.onNavbarRightIconClick = this.onNavbarRightIconClick.bind(this);
        this.onNavbarLeftButtonClick = this.onNavbarLeftButtonClick.bind(this);
        this.onNavbarRightButtonClick = this.onNavbarRightButtonClick.bind(this);
        this.setError = this.setError.bind(this);
        this.setForbidden = this.setForbidden.bind(this);
    }

    render() {
        const { error } = this.state;

        if(error !== null && error !== undefined && this.props.location.pathname !== ROUTES.ERROR) {
            return(
                <Redirect push from={this.props.location.pathname} to={{
                    pathname: ROUTES.ERROR,
                    state: { error: error, referer: this.props.location.pathname }
                }} />
            );
        }

        const { logout } = this.state;

        if(logout && this.props.location.pathname !== ROUTES.ERROR) {
            return(
                <Redirect to={ROUTES.LOGOUT} />
            );
        }

        const { dashboard } = this.state;

        if(dashboard && this.props.location.pathname !== ROUTES.DASHBOARD) {
            return(
                <Redirect to={ROUTES.DASHBOARD} />
            );
        }

        const { user_dashboard } = this.state;

        if(user_dashboard && this.props.location.pathname !== ROUTES.USER_DASHBOARD) {
            return(
                <Redirect to={ROUTES.USER_DASHBOARD} />
            );
        }

        let appContext = this.props.AppContext;
        const { forbidden } = this.state;
        if(this._authenticatedPage && this.props.location.pathname !== ROUTES.LOGIN && 
            this.props.location.pathname !== ROUTES.ERROR && 
            (appContext.User === null || appContext.User === undefined || forbidden))
        {
            return (
                <Redirect to={ROUTES.LOGIN} />
            );
        }

        if(this.props.AppContext.User !== null && this.props.AppContext.User !== undefined && 
            this.props.location.pathname !== ROUTES.ERROR && 
            this.props.location.pathname !== ROUTES.LOGOUT) {           
            if(this._navbarRightButtonIcon === null && 
                this._navbarRightButtonText === null && 
                this._navbarRightButtonIconColor === null && 
                this._navbarRightButtonClassName === null && 
                this._navbarRightButtonVariant === null) {
                this._navbarRightButtonIcon = "BoxArrowRight";
                this._navbarRightButtonIconColor = "white";
                this._navbarRightButtonClassName = "btn-danger";
                this._navbarRightButtonVariant = "danger";
                this._navbarRightButtonType = "button";
            }
       }

        this.preRender();

        this._navbar = React.createRef();

        return( 
            <div className={'App ' + this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                <Component.Navbar 
                    ref={this._navbar}
                    AppContext={this.props.AppContext}
                    location={this.props.location}
                    Title={this._title}
                    LeftIcon = {this._navbarLeftIcon}
                    LeftIconClassName = {this._navbarLeftIconClassName}
                    onLeftIconClick={this.onNavbarLeftIconClick}
                    LeftButtonIcon={this._navbarLeftButtonIcon}
                    LeftButtonIconColor={this._navbarLeftButtonIconColor}
                    LeftButtonIconSize={this._navbarLeftButtonIconSize}
                    LeftButtonText={this._navbarLeftButtonText}
                    LeftButtonClassName={this._navbarLeftButtonClassName}
                    LeftButtonVariant={this._navbarLeftButtonVariant}
                    onLeftButtonClick={this.onNavbarLeftButtonClick}
                    LeftButtonType={this._navbarLeftButtonType}
                    RightIcon = {this._navbarRightIcon}
                    RightClassName = {this._navbarRightClassName}
                    onRighIconClick={this.onNavbarRightIconClick}
                    RightButtonIcon={this._navbarRightButtonIcon}
                    RightButtonIconSize={this._navbarRightButtonIconSize}
                    RightButtonIconColor={this._navbarRightButtonIconColor}
                    RightButtonText={this._navbarRightButtonText}
                    RightButtonClassName={this._navbarRightButtonClassName}
                    RightButtonVariant={this._navbarRightButtonVariant}
                    onRightButtonClick={this.onNavbarRightButtonClick}
                    RightButtonType={this._navbarRightButtonType}
                    Authenticated={this._isAuthenticated}
                    HideMenu={this._hideMenu}
                    TitleLink={this._titleLink} />
                <SharedComponent.Container className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' w-100 m-0 p-0'}>
                    {this.renderPage()}
                </SharedComponent.Container>
                <Component.Footer />
            </div>
        );
    }

    renderPage() {
        
    }

    preRender() {
        
    }

    onNavbarLeftIconClick(event) {
        event.preventDefault();
    }

    onNavbarRightIconClick(event) {
        event.preventDefault();
    }

    onNavbarLeftButtonClick(event) {
        event.preventDefault();
    }

    onNavbarRightButtonClick(event) {
        event.preventDefault();
        if(this.props.AppContext.User !== null && this.props.AppContext.User !== undefined) {
            this.props.AppContext.User = null;
            this.state.logout = true;
            this.setState(this.state);
        }
    }

    setError(error) {
        this.state.error = error;
        this.setState(this.state);
    }

    setForbidden() {
        this.state.forbidden = true;
        this.setState(this.state);
    }
}
