import React from 'react';
import { Redirect } from 'react-router';
import { Form, FormGroup, Container } from "../shared";
import * as Config from '../config';

export class ListForm extends Form {
    _listFormComponentName = null;
    _listLocalStorageKeyName = null;
    _listAPIURL = null;
    _listItemTextMaxLength = Config.ListItemTextMaxLength;
    _toolbarItems = [];
    _standardToolbarButtons = { 
        refresh: true, 
        new: true
    };
    _toolbarIconSize = 36;
    _toolbarIconColor = 'white';
    _newItemRouteName = null;

    constructor(props) {
        super(props);

        this.state = { 
            loaded: false,
            items: [],
            error: null,
            toolbarAction: ''
        };

        this.loadItems = this.loadItems.bind(this);
        this.onLoadApiSuccess = this.onLoadApiSuccess.bind(this);
        this.onLoadApiFail = this.onLoadApiFail.bind(this);
        this.onItemSelect = this.onItemSelect.bind(this);
        this.onItemsSearch = this.onItemsSearch.bind(this);
        this.getAPIURL = this.getAPIURL.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onToolbarAction = this.onToolbarAction.bind(this);

        this._listItemTextMaxLength = this.props.ListItemTextMaxLength || 30;
        this._toolbarItems = this.props.ToolbarItems || null;
        if(this.props.StandardToolbarButtons !== undefined && this.props.StandardToolbarButtons !== null){
            if(this.props.StandardToolbarButtons.new === undefined || this.props.StandardToolbarButtons.new === null) {
                this._standardToolbarButtons.new = false;
            }
            if(this.props.StandardToolbarButtons.refresh === undefined || this.props.StandardToolbarButtons.refresh === null) {
                this._standardToolbarButtons.refresh = false;
            }
        }
        else {
            this._standardToolbarButtons = null;
        }
        this._toolbarIconSize = this.props.ToolbarIconSize || 36;
        this._toolbarIconColor = this.props.ToolarIconColor || 'white';
    }

    renderFormComponents() {
        const { loaded, items, toolbarAction } = this.state;

        if(toolbarAction === 'new') {
            return (
                <Redirect to={this._newItemRouteName} />
            );
        }

        if(!loaded) {
            return(<div></div>);
        }

        return (
            <FormGroup className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                <Container className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' m-0 p-0 w-100'}>
                {
                    React.createElement(this._listFormComponentName, {
                        AppContext: this.props.AppContext,
                        onSelect: this.onItemSelect,
                        onSearch: this.onItemsSearch,
                        items: items,
                        ListItemTextMaxLength: this._listItemTextMaxLength,
                        ToolbarItems: this._toolbarItems,
                        onToolbarAction: this.onToolbarAction,
                        ToolbarActionValue: this.state.toolbarAction,
                        StandardToolbarButtons: this._standardToolbarButtons,
                        ToolbarIconSize: this._toolbarIconSize,
                        ToolbarIconColor: this._toolbarIconColor
                    })
                }
                </Container>
            </FormGroup>
        );
    }

    onLoadApiSuccess(result) {
        this.state.loaded = true;
        this.state.items = result.data;
        this.state.error = null;
        if(this._listLocalStorageKeyName !== null && this._listLocalStorageKeyName !== undefined) {
            this.props.AppContext.LocalStorageProvider.setItem(this._listLocalStorageKeyName, { 
                "cache_tmsp": (new Date()).toISOString(), 
                "data": result.data
            });
        }
        this.setState(this.state);
    }

    onLoadApiFail(error) {
        if(error.contains("403")) {
            this.setForbidden();
            return;
        }
        this.state.loaded = false;
        this.state.items = [];
        this.state.error = error;
        this.setState(this.state);
    }

    getAPIURL() {
        return this._listAPIURL;
    }

    loadItems() {
        const { loaded, error, toolbarAction } = this.state;

        if(loaded && error !== null) return;

        let itemList = null;
        
        if(this._listLocalStorageKeyName !== null && this._listLocalStorageKeyName !== undefined && toolbarAction !== 'refresh') {
            itemList = this.props.AppContext.LocalStorageProvider.getItem(this._listLocalStorageKeyName);
        }

        if(itemList === null || itemList.data === null) {
            this.props.AppContext.ApiProvider._appContext = this.props.AppContext;
            let api_url = this.getAPIURL();
            this.props.AppContext.ApiProvider.send(api_url, 'GET',
                this.onLoadApiSuccess, 
                this.onLoadApiFail);
        }
        else {
            this.state.loaded = true;
            this.state.items = itemList.data;
            this.state.error = null;
            this.setState(this.state);
        }

    }

    componentDidMount() {
        this.loadItems();
    }

    onItemSelect(id) {
        this.props.onSelect(id);
    }

    onItemsSearch(e) {
        this.props.onSearch(e);
    }

    refresh() {
        if(this._listLocalStorageKeyName !== null && this._listLocalStorageKeyName !== undefined) {
            this.props.AppContext.LocalStorageProvider.removeItem(this._listLocalStorageKeyName);
        }
        this.setState({ loaded: false });
        this.loadItems();
    }

    onToolbarAction(action) {
        this.setState({ toolbarAction: action });
        if(action === 'refresh') this.refresh();
    }
}
