import React from 'react';
import { UserListForm } from '../forms';
import * as ROUTES from '../routes';
import { Redirect } from 'react-router';
import { BasePage } from './BasePage';
import * as Config from '../config';

export class LoginPage extends BasePage {
  _pinValue = "";

  constructor(props) {
    super(props);

    this._authenticatedPage = false;
    this._hideMenu = true;
    this._titleLink = ROUTES.LOGIN;

    this.state = { 
      user: this.props.AppContext.User,
      userAuthed: (this.props.AppContext.User !== null && this.props.AppContext.User !== undefined ? true : false),
      returnToLogin: false
    };

    this.UserSelected = this.UserSelected.bind(this);
    this.AuthUser = this.AuthUser.bind(this);
    this.onAuthUser = this.onAuthUser.bind(this);
    this.onAuthUserApiSuccess = this.onAuthUserApiSuccess.bind(this);
    this.onAuthUserApiFail = this.onAuthUserApiFail.bind(this);
  }

  preRender() {
    super.preRender();

    this._navbarLeftIcon = null;
    this._navbarLeftButtonText = null;
    this._navbarLeftButtonClassName = null;
    this._navbarLeftButtonVariant = null;
    this._navbarRightButtonText = null;
    this._navbarRightButtonClassName = null;
    this._navbarRightButtonVariant = null;

    const { returnToLogin } = this.state;
    const { user } = this.state;
    if(user !== null  && user !== undefined && (!returnToLogin)) {
      this._navbarLeftButtonText = "Cancel";
      this._navbarLeftButtonClassName = "btn-danger";
      this._navbarLeftButtonVariant = "danger";

      this._navbarRightButtonIcon = null;
      this._navbarRightButtonText = "Login";
      this._navbarRightButtonClassName = "btn-success";
      this._navbarRightButtonVariant = "success";
      this._navbarRightButtonType = "submit";
    }
  
  }

  renderPage() {
    super.renderPage();

    const { returnToLogin } = this.state;
    if(returnToLogin) {
      this.props.AppContext.User = null;
      this.state.returnToLogin = false;
    }

    const { userAuthed } = this.state;
    if(userAuthed && (!returnToLogin)) {
      this.props.AppContext.User = this.state.user;

      return (
        <Redirect to={ROUTES.DASHBOARD} />
      );
    }

    const { user } = this.state;
    if(user !== null && user !== undefined && (!returnToLogin)) {
      return(
        <input type="number" id="pin" name="pin"
            style={{width: 90 + '%'}} 
            pattern="\d{4}" required
            onKeyDown={(event) => { if (event.keyCode === 13){ event.preventDefault(); event.stopPropagation(); this.onNavbarRightButtonClick(event); }}}
            onChange={(event) => { event.preventDefault(); this._pinValue = event.target.value; }}
            className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' m-0 p-0'} />
      )
    }

    return( 
      <UserListForm id={'SelectUser'} 
        AppContext={this.props.AppContext} 
        onUserSelected={this.UserSelected}
        Page={this}
        Active='Y' />
    );
  }

  UserSelected(short_name) {
    this.state.user = { "short_name" : short_name };
    this.setState(this.state);
  }

  onNavbarLeftButtonClick(event) {
    super.onNavbarLeftButtonClick(event);
  
    const { user } = this.state;
    if(user !== null && user !== undefined) {
      this.props.AppContext.User = null;
      this.state.returnToLogin = true;
      this.setState(this.state);
    }
  }

  onNavbarRightButtonClick(event) {
    super.onNavbarRightButtonClick(event);

    const { user } = this.state;
    if(user !== null && user !== undefined) {
      this.AuthUser();
    }
  }

  onAuthUserApiSuccess(result) {
    this.state.userAuthed = true;
    this.state.error = null;
    this.state.user = result.data;
    this.props.AppContext.User = result.data;
    this.props.AppContext.CookieProvider.setUser(this.props.AppContext.User);
    this.setState(this.state);
  }

  onAuthUserApiFail(error) {
    this.props.AppContext.User = null;
    this.props.AppContext.CookieProvider.removeUser();
    super.setError(error);
  }

  onAuthUser(short_name, pin) {
      let data = {
          "$schema": "https://schema.bellamkonda.com/json/pwms/api/v1/LoginAPIRequest.json",
          "short_name": short_name,
          "pin": pin,
          "dev": (Config.config.key === '' ? true : false)
      };
      this.props.AppContext.ApiProvider._appContext = this.props.AppContext;
      this.props.AppContext.ApiProvider.send(Config.API_LOGIN, 'POST',
          this.onAuthUserApiSuccess, 
          this.onAuthUserApiFail, null, data);
  }

  AuthUser() {
    const { user } = this.state;
    this.onAuthUser(user.short_name, this._pinValue);
  }
}
