import * as Components from '../../component';
import { ChangeItemForm } from '../ChangeItemForm';
import * as Config from '../../config';
import * as ROUTES from '../../routes';

export class AccountChangeForm extends ChangeItemForm {
    constructor(props) {
        super(props);

        if(this.props.ChangeAction === "change_password") {
            this._changeItemFormComponentName = Components.AccountChangePassword;
        }
        this._afterChangeItemRoute = ROUTES.ACCOUNT_BY_ID.replace(":id", this.props.ItemId);
        this._changeItemApiSchemaURL = "https://schema.bellamkonda.com/json/pwms/api/v1/Account.json";
    }

    getChangeItemAPIURL() {
        return Config.API_ACCOUNT_CHANGE_ACTION + "?t=" + this.props.Item.rec_tmsp;
    }
}
