import React from 'react';
import * as SharedComponent from '../shared/component';
import { Toolbar } from './Toolbar';

export class DetailComponent extends SharedComponent.Component {
    _toolbarItems = null;

    constructor(props) {
        super(props);

        this.state = {
            item: null,
            toolbarAction: ''
        };

        this.renderToolbar = this.renderToolbar.bind(this);
        this.preToolbarRender = this.preToolbarRender.bind(this);

        this._toolbarItems = this.props.ToolbarItems;
    }

    preToolbarRender() {

    }

    renderToolbar() {
        this.preToolbarRender();

        if(this._toolbarItems === null &&  (this.props.StandardToolbarButtons === null || this.props.StandardToolbarButtons === undefined)) return null;

        if(this._toolbarItems === null) {
            this._toolbarItems = [];
        }

        if(this.props.StandardToolbarButtons !== null && this.props.StandardToolbarButtons !== undefined) {
            if(this.props.StandardToolbarButtons.delete !== undefined && this.props.StandardToolbarButtons.delete !== null && this.props.StandardToolbarButtons.delete !== '') {
                var deleteToolbarItem = this._toolbarItems.find(ti => ti['Action'] === 'delete');
                if(deleteToolbarItem === undefined || deleteToolbarItem === null){
                    this._toolbarItems.push({
                        'Icon': 'XSquare',
                        'Text': this.props.StandardToolbarButtons.delete,
                        'Action': 'delete'
                    });
                }
            }

            if(this.props.StandardToolbarButtons.change !== undefined && this.props.StandardToolbarButtons.delete !== null && this.props.StandardToolbarButtons.change !== '') {
                var changeToolbarItem = this._toolbarItems.find(ti => ti['Action'] === 'change');
                if(changeToolbarItem === undefined || changeToolbarItem === null){
                    this._toolbarItems.push({
                        'Icon': 'Pencil',
                        'Text': this.props.StandardToolbarButtons.change,
                        'Action': 'change'
                    });
                }
            }
        }

        return(
            <Toolbar 
                Action={this.props.onToolbarAction}
                ActionValue={this.state.toolbarAction}
                Items={this._toolbarItems} 
                IconSize={this.props.ToolbarIconSize || 36}
                IconColor={this.props.ToolbarIconColor || 'white'}
                AppContext={this.props.AppContext} />
        );
    }

    renderComponent() {
        return( 
            <SharedComponent.Div className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
            </SharedComponent.Div>
        );
    }
}
