import React from 'react';

export const Image = (props) => {
    return (
        <img src={props.src} 
            className={props.className}
            style={props.style} 
            alt={props.alt} 
            width={props.width} 
            height={props.height} />
    );
}
