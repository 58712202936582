import React from 'react';
// eslint-disable-next-line
import logo from './logo.svg';
import './App.css';
import * as ROUTES from './routes';
import { Route, Switch } from 'react-router-dom'
import { HomePage, LoginPage, DashboardPage, LogoutPage, ErrorPage } from './pages';
import { AccountsPage, AccountPage, AccountNewPage, AccountChangePage } from './pages';
import { ServicesPage, ServicePage, CompanyPage } from './pages';
import { FinancialAccountsPage, FinancialAccountPage } from './pages';
import AppContext from './AppContext';
import { ApiService, CookieService, LocalStorageService } from './service';
import { ApiProvider, CookieProvider, LocalStorageProvider } from './provider';
import { axiosInstance } from "./util/http/axiosInstance";
import * as Config from './config';

class App extends React.Component {
  static contextType = AppContext;

  _cookieService = null;
  _cookieProvider = null;
  _user = null;

  constructor(props) {
    super(props);
  
    if (process.env.NODE_ENV !== 'test') {
      this._cookieService = new CookieService();
    }

    this._cookieProvider = new CookieProvider(this._cookieService);

    this._user = this._cookieProvider.getUser();
  }

  render() {
    let context = this.context;
    let apiService = null;
    let localStorageService = null;
    if (process.env.NODE_ENV !== 'test') {
      axiosInstance.defaults.baseURL = Config.config.apiProtocol + '://' + Config.config.apiHost;
      axiosInstance.defaults.mode = 'cors';
      apiService = new ApiService(axiosInstance);
      localStorageService = new LocalStorageService();
    }

    context.ApiProvider = new ApiProvider(apiService);
    context.CookieProvider = this._cookieProvider;
    context.LocalStorageProvider = new LocalStorageProvider(localStorageService);

    context.User = this._user;

    this.context = context;

    document.body.classList.add(this.context.Theme.bg);
    document.body.classList.add(this.context.Theme.txt);
    document.getElementById('root').classList.add(this.context.Theme.bg);
    document.getElementById('root').classList.add(this.context.Theme.txt);

    return (
      <Switch>
        <Route exact path={ROUTES.HOME} render={(props) => <HomePage {...props} AppContext={context} />} />
        <Route exact path={ROUTES.LOGIN} render={(props) => <LoginPage {...props} AppContext={context} />} />
        <Route exact path={ROUTES.DASHBOARD} render={(props) => <DashboardPage {...props} AppContext={context} />} />
        <Route exact path={ROUTES.ACCOUNTS} render={(props) => <AccountsPage {...props} AppContext={context} />} />
        <Route exact path={ROUTES.ACCOUNT_NEW} render={(props) => <AccountNewPage {...props} AppContext={context} />} />
        <Route exact path={ROUTES.ACCOUNT_CHANGE_ACTION} render={(props) => <AccountChangePage {...props} AppContext={context} />} />
        <Route exact path={ROUTES.ACCOUNT_BY_ID} render={(props) => <AccountPage {...props} AppContext={context} />} />
        <Route exact path={ROUTES.LOGOUT} render={(props) => <LogoutPage {...props} AppContext={context} />} />
        <Route exact path={ROUTES.ERROR} render={(props) => <ErrorPage {...props} AppContext={context} />} />
        <Route exact path={ROUTES.SERVICES} render={(props) => <ServicesPage {...props} AppContext={context} />} />
        <Route exact path={ROUTES.SERVICE_BY_ID} render={(props) => <ServicePage {...props} AppContext={context} />} />
        <Route exact path={ROUTES.COMPANY_BY_ID} render={(props) => <CompanyPage {...props} AppContext={context} />} />
        <Route exact path={ROUTES.FINANCIAL_ACCOUNTS} render={(props) => <FinancialAccountsPage {...props} AppContext={context} />} />
        <Route exact path={ROUTES.FINANCIAL_ACCOUNT_BY_ID} render={(props) => <FinancialAccountPage {...props} AppContext={context} />} />
      </Switch>
    );
  }
};

export default App;
