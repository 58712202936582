import * as Config from '../../config';
import { DetailForm } from '../DetailForm';
import * as Components from '../../component';

export class FinancialAccountForm extends DetailForm {
    constructor(props) {
        super(props);

        this._detailItemFormComponentName = Components.FinancialAccount;
        this._listLocalStorageKeyName = "FinancialAccountList";
    }

    getItemAPIURL() {
        let url = Config.API_FINANCIAL_ACCOUNT_BY_ID;

        url = url.replace("{:id}", this.props.ItemId);
        url += '?w=' + parseInt(window.innerWidth * Config.credentialImageConfig.width_percent / 100);
        url += '&h=' + Config.credentialImageConfig.height;
        url += '&bc=' + this.props.AppContext.Theme.bg_color;
        url += '&fc=' + this.props.AppContext.Theme.txt_color;
        url += '&if=' + Config.credentialImageConfig.image_format;
        url += '&lc=' + this.props.AppContext.Theme.line_color;
        url += '&fs=' + Config.credentialImageConfig.font_size;
        url += '&fn=' + Config.credentialImageConfig.font_name;

        return url;
    }

    getDeleteItemAPIURL() {
        let url = Config.API_FINANCIAL_ACCOUNT_BY_ID;
        url = url.replace("{:id}", this.props.ItemId);
        url += '?t=' + this.state.item.rec_tmsp;
        return url;
    }
}
