import React from 'react';
import * as SharedComponent from '../shared/component';

export class Footer extends React.Component {
    
    render() {
        return (
            <footer className="mt-1">
                <SharedComponent.Container className='m-0 p-0 w-100 pt-2'>
                    <hr className="border-white p-0 m-0" />
                    <span className="p-0 m-0">&copy; Bellamkonda.com. All rights reserved.</span>
                </SharedComponent.Container>
            </footer>
        );
    }
}
