import React from 'react';
import * as Config from './config';

const AppContext = React.createContext({
  Theme: Config.defaultTheme,
  User: null,
  ApiProvider: null,
  CookieProvider: null,
  LocalStorageProvider: null
});

AppContext.displayName = 'GlobalApplicationContext';

export default AppContext;
