import { AccountChangeForm } from '../../forms';
import { ChangeItemPage } from './../ChangeItemPage';
import * as ROUTES from '../../routes';

export class AccountChangePage extends ChangeItemPage {
    constructor(props) {
        super(props);

        this._changeItemFormControl = AccountChangeForm;
        this._backPage = ROUTES.ACCOUNT_BY_ID.replace(":id", this._itemId);
        this._afterChangeRoute = ROUTES.ACCOUNT_BY_ID.replace(":id", this._itemId);
    }
}
