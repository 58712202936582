import React from 'react';
import { Link } from 'react-router-dom';
import ReactBootStrapNavbar from 'react-bootstrap/Navbar';
import { slide as Menu } from 'react-burger-menu'
import * as SharedComponent from '../shared/component';
import * as Config from '../config';
import * as Icon from 'react-bootstrap-icons';
import * as ROUTES from '../routes';

export class Navbar extends SharedComponent.Navbar {
    constructor(props) {
        super(props);

        this.state = {
            title: this.props.Title,
            titleLink: this.props.TitleLink ? this.props.TitleLink : ROUTES.DASHBOARD,
            menuIsOpen: false
        }

        this._renderIcon = this._renderIcon.bind(this);
        this._renderButton = this._renderButton.bind(this);
        this._renderMenu = this._renderMenu.bind(this);
    }

    _renderIcon(pngFileName, cssClassName, clickFn, imgClassName) {
        return(pngFileName !== null ? 
            <SharedComponent.Link className={cssClassName}
                onClick={(event) => { event.preventDefault(); clickFn(event); }}>
                    <SharedComponent.Image 
                        src={'/Content/Icons/' + pngFileName + '.png'} 
                        className={imgClassName} />
            </SharedComponent.Link> : '');
    }

    _renderButton(btnIcon, btnText, btnId, cssClassName, btnVariant, clickFn, btnType, iconSize, iconColor) {
        let ButtonIconTag = null;
        if (btnIcon !== null) {
            ButtonIconTag = Icon[btnIcon];
        }

        return ((btnIcon !== null 
            || btnText !== null) ? 
            <SharedComponent.Button id={btnId} 
                className={cssClassName} 
                variant={btnVariant} 
                onClick={(event) => { event.preventDefault(); clickFn(event); }} 
                type={btnType}>
                {
                    (btnIcon !== null ? 
                        <ButtonIconTag size={iconSize || '24px'} 
                            color={iconColor || 'inherit'} /> 
                    : '')
                }
                {(btnText !== null) ? btnText : '' }
            </SharedComponent.Button> : '');
    }

    _renderMenu() {
        let { menuIsOpen } = this.state;
        return (
            this.props.HideMenu === true ? "" :
                <SharedComponent.Div>
                    <SharedComponent.Button id="navbar-menu-button"  variant="info" onClick={(event) => { event.preventDefault(); this.setState({ menuIsOpen: !menuIsOpen }); }} type="button"><Icon.List size="24px" color="white" /></SharedComponent.Button>
                    <SharedComponent.Div style={{ marginTop: "2px" }}>
                        <Menu isOpen={menuIsOpen} customBurgerIcon={ false } customCrossIcon={ false }>
                            <Link to={ROUTES.DASHBOARD} 
                                className={this.props.location.pathname === ROUTES.DASHBOARD ? "bg-dark" : ""}>
                                Dashboard
                            </Link>
                            <Link to={ROUTES.ACCOUNTS} 
                                className={this.props.location.pathname === ROUTES.ACCOUNTS ? "bg-dark" : ""}>
                                    Accounts
                            </Link>
                            <Link to={ROUTES.SERVICES} 
                                className={this.props.location.pathname === ROUTES.SERVICES ? "bg-dark" : ""}>
                                    Services
                            </Link>
                            <Link to={ROUTES.FINANCIAL_ACCOUNTS} 
                                className={this.props.location.pathname === ROUTES.FINANCIAL_ACCOUNTS ? "bg-dark" : ""}>
                                    Financial Accounts
                            </Link>
                            <Link to={ROUTES.LOGOUT}>Logout</Link>
                        </Menu>
                    </SharedComponent.Div>
                </SharedComponent.Div>
        );
    }

    render() {
        let { title, titleLink } = this.state;
        if(title.length > Config.NavBarTitleMaxLength) {
            title = title.substring(0, (Config.NavBarTitleMaxLength - 1)) + "...";
        }

        return (
            <SharedComponent.Container className='m-0 p-0 w-100 pb-1'>
                <ReactBootStrapNavbar fixed="top" sticky="top" expand="lg" 
                    bg={this.props.AppContext.Theme.Navbar.bg} 
                    variant={this.props.AppContext.Theme.Navbar.variant}
                    className={'m-0 p-0 w-100'}
                    style={this.props.style}
                    default
                    collapseOnSelect>
                        <SharedComponent.Row className='m-0 p-0 w-100 pt-1 pb-1 pr-1 pl-1' style={{"height": "46px"}}>
                            <SharedComponent.Col className="col-3 m-0 p-0 text-left">
                                <SharedComponent.Div className="m-0 p-0 w-100">
                                    {
                                        (this.props.LeftIcon !== null 
                                            || this.props.LeftButtonIcon !== null 
                                            || this.props.LeftButtonText !== null) ?
                                            <SharedComponent.Div>
                                                {
                                                    this._renderIcon(this.props.LeftIcon, 
                                                        this.props.LeftIconClassName, 
                                                        this.props.onLeftIconClick, 
                                                        'float-left')
                                                }
                                                {
                                                    this._renderButton(this.props.LeftButtonIcon, 
                                                        this.props.LeftButtonText, 
                                                        "navbar-left-button", 
                                                        this.props.LeftButtonClassName, 
                                                        this.props.LeftButtonVariant, 
                                                        this.props.onLeftButtonClick, 
                                                        this.props.LeftButtonType, 
                                                        this.props.LeftButtonIconSize, 
                                                        this.props.LeftButtonIconColor)
                                                }
                                            </SharedComponent.Div>
                                        :
                                        this._renderMenu()
                                    }
                                </SharedComponent.Div>
                            </SharedComponent.Col>
                            <SharedComponent.Col className='text-center navbar-text text-white col-6 m-0 p-0 navbar-title-vertical-center'>
                                <SharedComponent.Div>
                                    <ReactBootStrapNavbar.Brand>
                                        <Link to={titleLink}>
                                            <span style={{ color:this.props.AppContext.Theme.txt_color, fontSize:"medium" }}>
                                                {title}
                                            </span>
                                        </Link>
                                    </ReactBootStrapNavbar.Brand>
                                </SharedComponent.Div>
                            </SharedComponent.Col>
                            <SharedComponent.Col className="col-3 m-0 p-0">
                                <SharedComponent.Div className="m-0 p-0 w-100 text-right">
                                    {
                                        this._renderIcon(this.props.RightIcon, 
                                            this.props.RightIconClassName, 
                                            this.props.onRightIconClick, 
                                            'float-right')
                                    }
                                    {
                                        this._renderButton(this.props.RightButtonIcon, 
                                            this.props.RightButtonText, 
                                            "navbar-right-button", 
                                            this.props.RightButtonClassName, 
                                            this.props.RightButtonVariant, 
                                            this.props.onRightButtonClick, 
                                            this.props.RightButtonType, 
                                            this.props.RightButtonIconSize, 
                                            this.props.RightButtonIconColor)
                                    }
                                </SharedComponent.Div>
                            </SharedComponent.Col>
                        </SharedComponent.Row>
                </ReactBootStrapNavbar>
            </SharedComponent.Container>
        );
    }
}
