export const ApplicationCode = "REACT_WEB";
export const credentialImageConfig = {
    width_percent: 90,
    height: 40,
    image_format: 'PNG',
    font_size: 30,
    font_name: 'cour'
};
export const NavBarTitleMaxLength = 20;
export const ListItemHeightDefault = 40;
export const ListItemHeightSecondLine = 20;
export const LinkTextMaxLength = 30;
export const ListItemTextMaxLength = 40;
export const ListItemSecondLineTextFontSize = 11;
export const ListItemSecondLineTextFontColor = 'grey';