import React from 'react';
import { Form, FormGroup, Container } from "../shared";

export class NewItemForm extends Form {
    _newItemFormComponentName = null;
    _newItemFormComponentRef = null;
    _dataLocalStorageKeyName = null;
    _loadData = false;
    _newItemApiSchemaURL = null;

    constructor(props, ref) {
        super(props);

        this.state = { 
            data: null,
            data_loaded: false
        };

        this._newItemFormComponentRef = React.createRef();
        this.onCreate = this.onCreate.bind(this);
        this.getDataAPIURL = this.getDataAPIURL.bind(this);
        this.loadData = this.loadData.bind(this);
        this.onLoadDataApiSuccess = this.onLoadDataApiSuccess.bind(this);
        this.onLoadDataApiFail = this.onLoadDataApiFail.bind(this);
        this.getCreateAPIURL = this.getCreateAPIURL.bind(this);
        this.create = this.create.bind(this);
        this.onCreateApiSuccess = this.onCreateApiSuccess.bind(this);
        this.onCreateApiFail = this.onCreateApiFail.bind(this);
    }

    renderFormComponents() {
        const { data_loaded, data } = this.state;
        if(!data_loaded || data === null ) return;
        return (
            <FormGroup className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                <Container className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' m-0 p-0 w-100'}>
                {
                    React.createElement(this._newItemFormComponentName, {
                        ref: this._newItemFormComponentRef,
                        AppContext: this.props.AppContext,
                        data: data
                    })
                }
                </Container>
            </FormGroup>
        );
    }

    onCreate() {
        if(!this._newItemFormComponentRef.current.validate()) {
            return -1;
        }

        return 0;
    }

    getDataAPIURL() {
        return null;
    }
    
    loadData() {
        if(!this._loadData) return;

        const { data_loaded, data } = this.state;

        if(data_loaded || data !== null) return;

        let itemData = null;
      
        if(this._dataLocalStorageKeyName !== null && this._dataLocalStorageKeyName !== undefined) {
            console.log(this.props);
            let lsItem = this.props.AppContext.LocalStorageProvider.getItem(this._dataLocalStorageKeyName);
            if(lsItem !== null) {
                itemData = lsItem.data;
            }
        }

        if(itemData === null) {
            this.props.AppContext.ApiProvider._appContext = this.props.AppContext;
            let api_url = this.getDataAPIURL();
            this.props.AppContext.ApiProvider.send(api_url, 'GET',
                this.onLoadDataApiSuccess, 
                this.onLoadDataApiFail);
        }
        else {
            this.state.data_loaded = true;
            this.state.data = itemData;
            this.state.error = null;
            this.setState(this.state);
        }
    }
    
    onLoadDataApiSuccess(result){
        this.state.data_loaded = true;
        this.state.data = result.data;
        if(this._dataLocalStorageKeyName !== null && this._dataLocalStorageKeyName !== undefined && result.data !== null) {
            this.props.AppContext.LocalStorageProvider.setItem(this._dataLocalStorageKeyName, { 
                "cache_tmsp": (new Date()).toISOString(), 
                "data": result.data
            });
        }
        this.setState(this.state);
    }
    
    onLoadDataApiFail(error) {
        this.props.onError(error);
    }
    
    getCreateAPIURL() {
        return null;
    }
    
    create(){
        let { item } = this._newItemFormComponentRef.current.state;

        if(this._newItemApiSchemaURL !== null) {
            item["$schema"] = this._newItemApiSchemaURL;
        }

        this.props.AppContext.ApiProvider._appContext = this.props.AppContext;
        let api_url = this.getCreateAPIURL();
        this.props.AppContext.ApiProvider.send(api_url, 'POST',
            this.onCreateApiSuccess, 
            this.onCreateApiFail,
            null,
            item);
    }
    
    onCreateApiSuccess(result){
        this.props.onItemCreated(result.data);
    }
    
    onCreateApiFail(error) {
        this.props.onError(error);
    }

    componentDidMount() {
        this.loadData();
    }
}
