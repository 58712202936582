import React from 'react';
import { ListGroup } from 'react-bootstrap';
import * as SharedComponent from '../shared/component';
import * as Icon from 'react-bootstrap-icons';
import * as Config from '../config';
import { Toolbar } from './Toolbar';

export class ListComponent extends SharedComponent.Component {
    _listItemType = null;
    _listItemIdPropName = null;
    _listItemNamePropName = null;
    _listItemSecondLinePropName = null;
    _listSearchMinTextLength = 3;
    _listItemNameCSSClass = "";
    _listItemRightPropName = null;

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            items: [],
            toolbarAction: ''
        };

        this.onItemSelect = this.onItemSelect.bind(this);
        this.onItemsSearch = this.onItemsSearch.bind(this);
    }

    renderSearch() {
        if(this.props.onSearch === null || this.props.onSearch === undefined) return null;

        return(
            <SharedComponent.Navbar >
                <SharedComponent.Div className="input-group mb-3">
                    <SharedComponent.Div className="input-group-prepend">
                        <span className="input-group-text"><Icon.Search /></span>
                    </SharedComponent.Div>
                    <input type="text" 
                        className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' form-control'} 
                        placeholder={"Search "+this._listItemType+"s"} aria-label={"Search "+this._listItemType+"s"} 
                        aria-describedby="basic-addon2"
                        onInput={(event) => { event.preventDefault(); this.onItemsSearch(event); }}></input>
                </SharedComponent.Div>
            </SharedComponent.Navbar>
        );
    }

    renderToolbar() {
        if(this.props.ToolbarItems === null &&  (this.props.StandardToolbarButtons === null || this.props.StandardToolbarButtons === undefined)) return null;

        let toolbarItems = this.props.ToolbarItems;
        if(toolbarItems === null) {
            toolbarItems = [];
        }

        if(this.props.StandardToolbarButtons !== null && this.props.StandardToolbarButtons !== undefined) {
            if(this.props.StandardToolbarButtons.new !== undefined && this.props.StandardToolbarButtons.new !== null && this.props.StandardToolbarButtons.new) {
                var newToolbarItem = toolbarItems.find(ti => ti['Action'] === 'new');
                if(newToolbarItem === undefined || newToolbarItem === null){
                    toolbarItems.push({
                        'Icon': 'PlusSquare',
                        'Action': 'new'
                    });
                }
            }
            if(this.props.StandardToolbarButtons.refresh !== undefined && this.props.StandardToolbarButtons.refresh !== null && this.props.StandardToolbarButtons.refresh) {
                var refreshToolbarItem = toolbarItems.find(ti => ti['Action'] === 'refresh');
                if(refreshToolbarItem === undefined || refreshToolbarItem === null){
                    toolbarItems.push({
                        'Icon': 'ArrowClockwise',
                        'Action': 'refresh'
                    });
                }
            }
        }
        return(
            <Toolbar 
                Action={this.props.onToolbarAction}
                ActionValue={this.state.toolbarAction}
                Items={toolbarItems} 
                IconSize={this.props.ToolbarIconSize || 36}
                IconColor={this.props.ToolbarIconColor || 'white'}
                AppContext={this.props.AppContext} />
        );
    }

    renderComponent() {
        const { loaded, items } = this.state;
        if(!loaded) {
            return(<div></div>);
        }

        let listItemHeight = Config.ListItemHeightDefault;
        if(this._listItemSecondLinePropName !== null) {
            listItemHeight += Config.ListItemHeightSecondLine;
        }

        return( 
            <SharedComponent.Div className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
                {this.renderToolbar()}
                {this.renderSearch()}
                <SharedComponent.Div className="d-flex">
                    <SharedComponent.Div className="d-inline-flex d-flex-fill w-100">
                        <ListGroup variant="flush" className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' m-0 p-0 w-100'}>
                            {
                                items.map((e, i) =>
                                    <ListGroup.Item key={e[this._listItemIdPropName]} action 
                                        onClick={(event) => { event.preventDefault(); this.onItemSelect(e[this._listItemIdPropName]); }}
                                        className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt + ' m-0 p-0 w-100 flex-fill ' + this._listItemNameCSSClass}>
                                        <SharedComponent.Div className="d-flex" style={{"height": listItemHeight+'px'}}>
                                            <SharedComponent.Div className="d-flex-fill w-100 list-item-vertical-center">
                                                <SharedComponent.Div className="m-0 pl-3 pr-3 p-0 w-100 text-left">
                                                    <SharedComponent.Div>
                                                        <span>{this.props.ListItemTextMaxLength > 0 && e[this._listItemNamePropName].length > this.props.ListItemTextMaxLength ? e[this._listItemNamePropName].substring(0, this.props.ListItemTextMaxLength-1)+'...' : e[this._listItemNamePropName]}</span>
                                                        {(this._listItemRightPropName !== null) ? <span className="float-right bg-info p-1 border border-danger rounded" style={{"fontSize":"small"}} dangerouslySetInnerHTML={{ __html: e[this._listItemRightPropName] }}></span> : ""}
                                                    </SharedComponent.Div>
                                                    {(this._listItemSecondLinePropName !== null && e[this._listItemSecondLinePropName] !== null) ? <SharedComponent.Div style={{"fontSize":Config.ListItemSecondLineTextFontSize+"pt","color":Config.ListItemSecondLineTextFontColor}}>&nbsp;&nbsp;&nbsp;&nbsp;{e[this._listItemSecondLinePropName]}</SharedComponent.Div> : ''}
                                                </SharedComponent.Div>
                                            </SharedComponent.Div>
                                        </SharedComponent.Div>
                                    </ListGroup.Item>
                                )
                            }
                        </ListGroup>
                    </SharedComponent.Div>
                    <SharedComponent.Div className="d-inline-flex">
                        &nbsp;
                    </SharedComponent.Div>
                </SharedComponent.Div>
            </SharedComponent.Div>
        );
    }

    componentDidMount() {
        this.setState({ loaded: true, items: this.props.items });
    }

    onItemSelect(id) {
        this.props.onSelect(id);
    }

    onItemsSearch(e) {
        if(this.props.onSearch === null || this.props.onSearch === undefined) return;

        this.props.onSearch(e);

        var filteredItems = this.props.items;
        if(e.target.value.length >= this._listSearchMinTextLength) {
            var searchText = e.target.value.toLowerCase();
            filteredItems = this.props.items.filter((e, i) => {
               return e[this._listItemNamePropName].toLowerCase().search(searchText) > -1; 
            });    
        }

        this.setState({ loaded: true, items: filteredItems });
    }
}
