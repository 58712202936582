import React from 'react';
import { BasePage } from './BasePage';
import { DashboardForm } from './../forms';

export class DashboardPage extends BasePage {
    constructor(props) {
        super(props);

        this._title = "Dashboard";
        this._authenticatedPage = true;
    }

    renderPage() {
        super.renderPage();

        return( 
            <DashboardForm 
                AppContext={this.props.AppContext} />
        );
    }
}
