import React from 'react';
import * as SharedComponent from '../shared/component';

export class ChangeItemComponent extends SharedComponent.Component {
    constructor(props) {
        super(props);

        this.state = {
            item: null
        };

        this.validate = this.validate.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    renderComponent() {
        return( 
            <SharedComponent.Div className={this.props.AppContext.Theme.bg + ' ' + this.props.AppContext.Theme.txt}>
            </SharedComponent.Div>
        );
    }

    validate() {
        return true;
    }

    onChange(event) {
        const { item } = this.state;
        item[event.target.name] = event.target.value;
        this.setState({ item: item });
    }
}
