import React from 'react';
import { Redirect } from 'react-router';
import { BasePage } from './BasePage';
import * as SharedComponent from './../shared';
import * as ROUTES from '../routes';
import * as Config from '../config';

export class LogoutPage extends BasePage {
  constructor(props) {
      super(props);

      this._authenticatedPage = false;
      this._hideMenu = true;
      this._titleLink = ROUTES.LOGIN;

      this.state = {
        login: false
      };

      this.LogoutUser = this.LogoutUser.bind(this);
      this.onLoginButtonClick = this.onLoginButtonClick.bind(this);
  }

  renderPage() {
    super.renderPage();

    let { login } = this.state;

    if(login) {
        return (
            <Redirect to={ROUTES.LOGIN} />
        );
    }

    this._isAuthenticated = false;

    return( 
        <div>
            <p>You have successfully logged out from Bellamkonda Personal Wealth Management System</p>
            <SharedComponent.Button className="btn-primary w-75" onClick={this.onLoginButtonClick}>Login</SharedComponent.Button>
        </div>
    );
  }

  LogoutUser() {
    this.props.AppContext.ApiProvider._appContext = this.props.AppContext;
    this.props.AppContext.ApiProvider.sendAsync(Config.API_LOGOUT, 'GET');
  }

  componentDidMount() {
    this.props.AppContext.User = this.props.AppContext.CookieProvider.getUser();
    if(this.props.AppContext.User) {
      this.LogoutUser();
    }
    this.props.AppContext.CookieProvider.removeUser();
    this.props.AppContext.LocalStorageProvider.clear();
}

  onLoginButtonClick(event) {
      event.preventDefault();
      this.state.login = true;
      this.setState(this.state);
  }

}
