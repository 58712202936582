import React from 'react';
import { BaseForm } from './BaseForm.ts';
import BootStrapForm from 'react-bootstrap/Form';

export class Form extends BaseForm {
    render() {
        return(
            <BootStrapForm 
                id={this.props.id}
                className={this.props.className}
                style={this.props.style}>
                {this.renderFormComponents()}
                {this.props.children}
            </BootStrapForm>
        );
    }
}
