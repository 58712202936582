import { LocalStorageService } from '../service';

export class LocalStorageProvider {
    localStorageService = null;

    constructor(localStorageService) {
        if (localStorageService === null)
            localStorageService = new LocalStorageService();

        this.localStorageService = localStorageService;

        this.setItem = this.setItem.bind(this);
        this.getItem = this.getItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    setItem(key, value) {
        this.localStorageService.setItem(key, value);
    }

    getItem(key) {
        return this.localStorageService.getItem(key);
    }

    removeItem(key) {
        this.localStorageService.removeItem(key);
    }

    clear() {
        this.localStorageService.clear();
    }
}