import promise from 'promise';
import * as sharedAxiosInstance from '../../shared/http/axiosInstance';

// Add a request interceptor 
export var axiosInstance = sharedAxiosInstance.axiosInstance;

axiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent 
  return config;
}, function (error) {
   // Do something with request error 
   return promise.reject(error);
});
