import React from 'react';
import BaseComponent from './BaseComponent.ts';
import BootStrapContainer from 'react-bootstrap/Container';

export class Container extends BaseComponent {
    render() {
        return( 
            <BootStrapContainer 
                className={this.props.className}
                style={this.props.style}
                fluid={this.props.fluid}>
                {this.props.children}    
            </BootStrapContainer>
        );
    }
}
