import React from 'react';
import BaseComponent from './BaseComponent.ts';
import BootStrapButton from 'react-bootstrap/Button';

export class Button extends BaseComponent {
    render() {
        return( 
            <BootStrapButton id={this.props.id} 
                name={this.props.name} 
                onClick={this.props.onClick} 
                variant={this.props.variant}
                className={this.props.className}
                style={this.props.style}
                as={this.props.as}
                type={this.props.type}
                value={this.props.value}
                href={this.props.href}>
                {this.props.children}    
            </BootStrapButton>
        );
    }
}
