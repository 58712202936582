export const HOME = '/';
export const LOGIN ='/login';
export const DASHBOARD = '/dashboard';
export const ACCOUNTS = '/accounts';
export const ACCOUNT = '/account/';
export const ACCOUNT_NEW = ACCOUNT + 'new';
export const ACCOUNT_BY_ID = ACCOUNT+':id';
export const ACCOUNT_CHANGE_ACTION = ACCOUNT_BY_ID+'/:action';
export const LOGOUT = '/logout';
export const ERROR = '/error';
export const USER_DASHBOARD = '/user/dashboard';
export const SERVICES = '/services';
export const SERVICE = '/service/';
export const SERVICE_BY_ID = SERVICE+':id';
export const COMPANY = '/company/';
export const COMPANY_BY_ID =  COMPANY+':id';
export const FINANCIAL_ACCOUNTS =  '/financial_accounts';
export const FINANCIAL_ACCOUNT =  '/financial_account/';
export const FINANCIAL_ACCOUNT_BY_ID =  FINANCIAL_ACCOUNT+':id';