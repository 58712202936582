import { ApiService } from '../ApiService';
import * as Config from '../../config';
import UserList from './data/UserList.json';
// eslint-disable-next-line
import UserListError from './data/UserListError.json';
import AccountList from './data/AccountList.json';
import Account from './data/Account.json';
import Login from './data/Login.json';

export class DummyApiService extends ApiService {
    dummyApiAuthToken = "ljsdfjlaskddjfalkhkjsadhfkldas";
    dummyProps = {
        "ApplicationCode": Config.ApplicationCode,
        "UserId": "1",
        "Timestamp": (new Date()).toISOString()
    };
    dummyUserId = "1";

    send(path, method, onsuccess, onfail, headers=null, data=null) {   
        this.onCallerSendSuccess = onsuccess;
        this.onCallerSendFail = onfail;

        let jsonData = null;

        if(path === Config.API_USER_LIST) {
            jsonData = UserList;
        }
        else if(path === Config.API_USER_LIST_ACTIVE) {
            // eslint-disable-next-line
            jsonData = UserList;
            // eslint-disable-next-line
            //jsonData = UserListError;
        }
        else if(path === Config.API_ACCOUNT_LIST) {
            jsonData = AccountList;
        }
        else if(path.startsWith(Config.API_ACCOUNT+'/')) {
            jsonData = Account;
            jsonData.data.rec_tmsp = (new Date()).toISOString();
            jsonData.data.created_on = (new Date()).toISOString();
            jsonData.data.cred_rec_tmsp = (new Date()).toISOString();
            jsonData.data.cred_eff_dt = (new Date()).toISOString();
        }
        else if(path === Config.API_LOGIN) {
            jsonData = Login;
            jsonData.data.user_id = data.user_id;
            jsonData.data.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            jsonData.data.token_expires = (new Date()).toISOString();
        }

        super.onSendSuccess(jsonData);
    }
}