import * as SHARED_PROVIDER from '../shared/provider';
import { DummyLocalStorageService } from '../service/dummy/DummyLocalStorageService';

export class LocalStorageProvider extends SHARED_PROVIDER.LocalStorageProvider {
    constructor(localStorageService) {
        if(localStorageService === null || process.env.NODE_ENV === 'test')
            localStorageService = new DummyLocalStorageService();

        super(localStorageService);
    }
}