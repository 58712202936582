import { trackPromise } from 'react-promise-tracker';

export class ApiService {
    _axiosInstance = null;

    constructor(axiosInstance = null) {
        this._axiosInstance = axiosInstance;

        this.send = this.send.bind(this);
        this.sendAsync = this.sendAsync.bind(this);
    }

    send(url, method, apikey, onsuccess, onfail, headers=null, data = null) {
        if(headers === null) {
            headers = {}
        }
        headers['Content-Type'] = 'application/json';
        headers['X-Api-Key'] = apikey;

        if (method === "GET" || method === "DELETE") {
            if(this._axiosInstance !== null) {
                if(method === "GET") {
                    trackPromise(
                        this._axiosInstance.get(url, {
                            headers: headers
                        })
                        .then(function(response) {
                            if(response.status === 200) {
                                onsuccess(response.data);
                            }
                            else {
                                onfail(response.data);
                            }
                        })
                        .catch(function (error) {
                            onfail(error);
                        })
                        .finally(function () {
                        })
                    );
                }
                else if(method === "DELETE") {
                    trackPromise(
                        this._axiosInstance.delete(url, {
                            headers: headers
                        })
                        .then(function(response) {
                            if(response.status === 200) {
                                onsuccess(response.data);
                            }
                            else {
                                onfail(response.data);
                            }
                        })
                        .catch(function (error) {
                            onfail(error);
                        })
                        .finally(function () {
                        })
                    );
                }
            }
            else {
                trackPromise(
                    fetch(url, {
                        crossDomain:true,
                        method: method,
                        headers: headers
                    })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            onsuccess(result);
                        },
                        (error) => {
                            onfail(error);
                        }
                    )
                );
            }
        }
        else {
            if(this._axiosInstance !== null) {
                if(method === "PATCH"){
                    trackPromise(
                        this._axiosInstance.patch(url, data, {
                            headers: headers
                        })
                        .then(function(response) {
                            if(response.status === 200) {
                                onsuccess(response.data);
                            }
                            else {
                                onfail(response.data);
                            }
                        })
                        .catch(function (error) {
                            onfail(error);
                        })
                        .finally(function () {
                        })
                    );
                }
                else {
                    trackPromise(
                        this._axiosInstance.post(url, data, {
                            headers: headers
                        })
                        .then(function(response) {
                            if(response.status === 200) {
                                onsuccess(response.data);
                            }
                            else {
                                onfail(response.data);
                            }
                        })
                        .catch(function (error) {
                            onfail(error);
                        })
                        .finally(function () {
                        })
                    );
                }
            }
            else {
                trackPromise(
                    fetch(url, {
                        crossDomain:true,
                        method: method,
                        body: JSON.stringify(data),
                        headers: headers
                    })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            onsuccess(result);
                        },
                        (error) => {
                            onfail(error);
                        }
                    )
                );
            }
        }
    }

    sendAsync(url, method, apikey, headers=null, data = null) {
        if(headers === null) {
            headers = {}
        }
        headers['Content-Type'] = 'application/json';
        headers['X-Api-Key'] = apikey;

        if (method === "GET" || method === "DELETE") {
            if(this._axiosInstance !== null) {
                if(method === "GET"){
                    this._axiosInstance.get(url, {
                        headers: headers
                    });
                }
                else if(method === "DELETE"){
                    this._axiosInstance.delete(url, {
                        headers: headers
                    });
                }
            }
            else {
                fetch(url, {
                    crossDomain:true,
                    method: method,
                    headers: headers
                });
            }
        }
        else {
            if(this._axiosInstance !== null) {
                if(method === "PATCH") {
                    this._axiosInstance.patch(url, data, {
                        headers: headers
                    });
                }
                else {
                    this._axiosInstance.post(url, data, {
                        headers: headers
                    });
                }
            }
            else {
                fetch(url, {
                    crossDomain:true,
                    method: method,
                    body: JSON.stringify(data),
                    headers: headers
                });
            }
        }
    }
}