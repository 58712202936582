import { ApiService } from '../service';

export class ApiProvider {
    apiService = null;
    onCallerSendSuccess = null;
    onCallerSendFail = null;

    constructor(apiService) {
        if (apiService === null)
            apiService = new ApiService();

        this.apiService = apiService;

        this.send = this.send.bind(this);
        this.onSendSuccess = this.onSendSuccess.bind(this);
        this.onSendFail = this.onSendFail.bind(this);
        this.sendAsync = this.sendAsync.bind(this);
    }

    send(url, method, onsuccess, onfail, headers=null, data=null) {
        this.onCallerSendSuccess = onsuccess;
        this.onCallerSendFail = onfail;

        this.apiService.send(url, method, this.onSendSuccess, this.onSendFail, headers, data);
    }

    onSendSuccess(result) {
        this.onCallerSendSuccess(result);
    }

    onSendFail(error) {
        this.onCallerSendFail(error);
    }

    sendAsync(url, method, headers=null, data=null) {
        this.apiService.sendAsync(url, method, headers, data);
    }
}