import { ListForm } from '../ListForm';
import * as Config from '../../config';
import * as Components from '../../component';

export class FinancialAccountListForm extends ListForm {
    constructor(props) {
        super(props);

        this._listFormComponentName = Components.FinancialAccountList;
        this._listLocalStorageKeyName = "FinancialAccountList";
        this._listAPIURL = Config.API_FINANCIAL_ACCOUNT_LIST_ACTIVE;
        this._standardToolbarButtons["new"] = false;
    }

    getAPIURL() {
        if(this.props.Active !== 'Y')
            return Config.API_FINANCIAL_ACCOUNT_LIST;

        return super.getAPIURL()
    }
}
